import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { toPascalCase, formatDateMonthTime } from 'modules'
import { Button } from 'primereact/button'
import { OtherTitles } from './OtherTitles'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch } from 'react-redux'
import { selectEpisodeFetchRequest, selectEpisodeUpdateRequest, selectEpisodeResultUpdateStatus, selectEpisodeUpdateErrorDetailMessage, selectEpisodeResultOriginal, selectEpisodeResultPresentation, selectEpisodeGuid, selectEpisodeResultHasChanges, selectEpisodeDraftHasDeletedTransmissions } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { push } from 'redux-first-history'
import { useSelector } from 'react-redux'
import 'assets/css/alcs-theme.css'
import { setEpisodeGuidAction, setEpisodeFetchStatusAction, setEpisodeDraftResultNumberAction, setEpisodeDraftResultSeriesAction, clearEpisodeDraftResultsNumberAction, clearEpisodeDraftResultsSeriesAction, clearEpisodeResultsTransmissionsFromDraftsAction, setEpisodeDraftResultsTransmissionsAction, setEpisodeDraftResultsTitleAction, setEpisodeUpdateStatusAction, clearEpisodeResultsTitleFromDraftsAction, clearProgrammeDraftResultsEpisodesAction, setProgrammeOriginalAggregateVersionAction, setEpisodeFetchResultsAction, setEpisodeDraftResultDurationAction, clearEpisodeDraftResultsDurationAction, setEpisodeDraftResultIdaAction, clearEpisodeDraftResultsIdaAction, setEpisodeDraftResultYearOfProductionAction, clearEpisodeDraftResultsYearOfProductionAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import DataEntry from 'api/DataEntry/AudioVisual'
import { TabView, TabPanel } from 'primereact/tabview'
import { Contributions } from './Contributions'
import { Transmissions } from './Transmissions'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import DataUpdate from 'api/DataUpdate/AudioVisual'
import { Toast } from 'primereact/toast'
import { CopyControl } from "../../../common/CopyControl"

// ReSharper disable once InconsistentNaming
export const EpisodeDetails = forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const toast = useRef(null)
    const { id, episodeId, resultsData, programmePrimaryTitle, otherProgrammeTitles, languages } = props
    const episodeGuid = useSelector(selectEpisodeGuid)
    const isEpisodeDataRequired = episodeId && episodeGuid !== episodeId
    if (isEpisodeDataRequired) dispatch(setEpisodeGuidAction(episodeId))
    const [isEditingTitle, setIsEditingTitle] = useState(false)
    const [showDataUpdate, setShowDataUpdate] = useState(false)
    const [dataUpdateKey, setDataUpdateKey] = useState(0)




    /* Dispatch */

    const setAcceptedDuration = (duration) => dispatch(setEpisodeDraftResultDurationAction(duration))
    const setAcceptedmainTitle = (title) => dispatch(setEpisodeDraftResultsTitleAction(title))
    const clearAcceptedDuration = (duration) => dispatch(clearEpisodeDraftResultsDurationAction(duration))
    const clearAcceptedmainTitle = (title) => dispatch(clearEpisodeResultsTitleFromDraftsAction(title))
    const setAcceptedIda = (ida) => dispatch(setEpisodeDraftResultIdaAction(ida))
    const clearAcceptedIda = (ida) => dispatch(clearEpisodeDraftResultsIdaAction(ida))
    const setAcceptedYear = (year) => dispatch(setEpisodeDraftResultYearOfProductionAction(year))
    const clearAcceptedYear = (year) => dispatch(clearEpisodeDraftResultsYearOfProductionAction(year))
    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const clearEpisodeDraftResultsTransmissions = () => dispatch(clearEpisodeResultsTransmissionsFromDraftsAction())
    const setEpisodeDraftResultsTransmissions = (transmissions) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmissions))
    const deleteTransmissionAvailable = useSelector(selectEpisodeDraftHasDeletedTransmissions)
    const setAcceptedTitles = (titlesToAccept) => dispatch(setEpisodeDraftResultsTitleAction(titlesToAccept))
    const setAcceptedSeries = (series) => dispatch(setEpisodeDraftResultSeriesAction(series))
    const clearAcceptedSeries = (series) => dispatch(clearEpisodeDraftResultsSeriesAction(series))
    const setAcceptedNumber = (number) => dispatch(setEpisodeDraftResultNumberAction(number))
    const clearAcceptedNumber = (number) => dispatch(clearEpisodeDraftResultsNumberAction(number))
    const updateStatus = useSelector(selectEpisodeResultUpdateStatus)
   

    var episodeData = useSelector(selectEpisodeResultPresentation)
    var episodeOriginalData = useSelector(selectEpisodeResultOriginal)
    const draftAvailable = useSelector(selectEpisodeResultHasChanges)
    let episodePrimaryTitle = episodeData && episodeData.titles && episodeData.titles.find(title => title.titleTypeId === 1)  
    const validFilmFormatIds = [1, 5]
    const isFilm = resultsData && validFilmFormatIds.includes(resultsData.formatId)
    const programmeTitle = programmePrimaryTitle && toPascalCase(programmePrimaryTitle.title)
    let altEpisodeTitles = isFilm ? otherProgrammeTitles : (episodeData && episodeData.titles && episodeData.titles.filter(title => title.titleTypeId !== 1));

    const [newTitle, setNewTitle] = useState(episodePrimaryTitle ? toPascalCase(episodePrimaryTitle.title) : '');
    if (newTitle === '' && episodePrimaryTitle) {
        setNewTitle(toPascalCase(episodePrimaryTitle.title))
    }

    useEffect(() => {
        if (updateStatus === 'complete') {
            dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
            dispatch(setEpisodeFetchResultsAction(episodeData)) 
            setShowDataUpdate(false)
            clearProgrammeEpisodeDraftResults()                       
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Update successful!', life: 3000 })            
        } else if (updateStatus?.status === 'error') {
            const errorMessage = updateStatus?.status?.error?.Message || 'An unknown error occurred'
            dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
            setShowDataUpdate(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${errorMessage}`, life: 3000 })           
            
        }
    }, [updateStatus]);

    const accept = () => {
        if (!draftAvailable) {
            handleCancelButtonClick()
        }        
        try {
            clearProgrammeEpisodeDraftResults()

            toast.current.show({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Episode Changes aborted',
                life: 3000
            })
            handleCancelButtonClick()
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Episode changes exists as Draft', life: 3000 })
    }

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to proceed to cancel?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept,
                reject
            })
        }
        else {
            handleCancelButtonClick()
        }
    }

    const handleCancelButtonClick = () => {
        dispatch(setEpisodeGuidAction(null))
        const currentUrl = window.location.href
        const baseUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'))
        dispatch(push(baseUrl))
    }

    // Validation function
    const validateEpisodeDetails = () => {
        // Perform validation checks
        // Return true if valid, false otherwise
        let isValid = true
        if (episodeData) {
            // Program Main title should not be empty
            if (!isFilm) {
                if (!episodePrimaryTitle || !episodePrimaryTitle.title) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Title is Mandatory' })
                    isValid = false
                }
                if(episodeData.number && episodeData.number <= 0){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Episode Number is Mandatory' })
                    isValid = false
                }
                if (episodeData.series && episodeData.series <= 0) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Series Number is Mandatory' })
                    isValid = false
                }
            }
            else if (!episodeData.durationMinutes || !episodeData.yearOfProduction) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration and Year of Production are Mandatory' })
                isValid = false
            }
            else if (episodeData.durationMinutes <= 0 || episodeData.yearOfProduction <= 0) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Duration and Year of Production are Mandatory' })
                isValid = false
            }
        }

        return isValid
    }

    useImperativeHandle(ref, () => ({
        validateEpisodeDetails
    }))

    //Save

    //On direct click of Accept in split button
    const splitSave = (event) => {
        if (!validateEpisodeDetails()) {
            return
        }
        handleUpdateTrigger()
    }

    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: (event) => {
                if (deleteTransmissionAvailable) {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'You are about to delete Transmissions. Do you want to continue?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: acceptSave,
                        reject: rejectTransmissionChange
                    })
                }
                else {
                    if (!validateEpisodeDetails()) {
                        return
                    }
                    handleUpdateTrigger()
                }

            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: () => {
                if (!validateEpisodeDetails()) {
                    return
                }
                handleUpdateTrigger().then(() => {
                    handleCancelButtonClick()
                }).catch(error => {
                    console.error('Update failed:', error);
                })

            }
        }
    ]

    const acceptSave = () => {
        if (!validateEpisodeDetails()) {
            return
        }
        handleUpdateTrigger().then(() => {
            handleCancelButtonClick()
        }).catch(error => {
            // Handle error if needed
            console.error('Update failed:', error);
        })
    }

    const rejectTransmissionChange = () => {
        clearEpisodeDraftResultsTransmissions()
        dispatch(setEpisodeDraftResultsTransmissions(episodeData?.transmissions))

    }

    const panelStyle = {
        background: 'white',
    }
    const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        alignItems: 'center'
    }

    const contentStyle = {
        padding: '20px'
    }
    const closebuttonStyle = {
        background: '#D9F8E6',
        color: 'black',
        border: 'none'
    }

    const programmeTitleStyle = {
        fontSize: '16px',
        fontWeight: 700,
    }
    const episodeTitleStyle = {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24.2px'
    }
    const codeStyle = {
        fontSize: '12px',
        background: '#005a08',
        color: 'white',
        borderRadius: '5px',
        padding: '5px'
    }
    const historyLabelStyle = {
        display: 'inline-flex',
        color: 'var(--alcsGrey)',
        fontSize: '12px',
        fontWeight: 700,
        margin: '0px 6px 3px 6px'
    }

    const historyDataStyle = {
        display: 'inline-flex',
        color: 'black',
        fontSize: '12px',
        fontWeight: 700
    }
    const rowHeightStyle = {
        height: '240px'
    }
    const otherInformationStyle = {
        height: '210px'
    }

    const footer = (
        <div className="app-footer">
            <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} />
            <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
        </div>
    )

    const handleDurationChange = (e) => {
        const newDuration = e.target.value;
        if (episodeOriginalData.durationMinutes === null && newDuration === "") {
            clearAcceptedDuration()
        }
        else if (newDuration === String(episodeOriginalData.durationMinutes)) {
            clearAcceptedDuration()
        }
        else {
            setAcceptedDuration(newDuration)
        }
    }

    const handleIdaChange = (e) => {
        const newIda = e.target.value;
        if (episodeOriginalData.idaCode === null && newIda === "") {
            clearAcceptedIda()
        }
        else if (newIda === String(episodeOriginalData.idaCode)) {
            clearAcceptedIda()
        }
        else {
            setAcceptedIda(newIda)
        }
    }

    const handleSeriesChange = (e) => {
        const newSeries = e.target.value;
        if (episodeOriginalData.series === null && newSeries === "") {
            clearAcceptedSeries()
        }
        else if (newSeries === String(episodeOriginalData.series)) {
            clearAcceptedSeries()
        }
        else {
            setAcceptedSeries(newSeries)
        }
    }

    const handleNumberChange = (e) => {
        const newEpisodeNumber = e.target.value;
        if (episodeOriginalData.number === null && newEpisodeNumber === "") {
            clearAcceptedNumber()
        }
        else if (newEpisodeNumber === String(episodeOriginalData.number)) {
            clearAcceptedNumber()
        }
        else {
            setAcceptedNumber(newEpisodeNumber)
        }
    }

    const handleYearChange = (e) => {
        const newDate = new Date(e.target.value)
        const newYear = newDate.getFullYear()

        if (episodeOriginalData.yearOfProduction === null && newYear === "") {
            clearAcceptedYear()
        }
        else if (newYear === episodeOriginalData.yearOfProduction) {
            clearAcceptedYear()
        }
        else {
            setAcceptedYear(newYear)
        }
    }

    const handleEditTitle = () => {
        setIsEditingTitle(true);
    }

    const handleUpdateTrigger = () => {
        return new Promise((resolve, rej) => {
            try {
                dispatch(setEpisodeUpdateStatusAction({ updateStatus: 'idle' }))
                setShowDataUpdate(false)
                setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
                setShowDataUpdate(true)
                resolve();
            } catch (error) {
                rej(error)
            }
        })
    };

    const handleSavePrimaryTitle = () => {
        // Add logic to save the new title
        setIsEditingTitle(false);
        const titlesArray = Object.values(episodeData.titles)
        const originalObjectIndex = titlesArray.findIndex(title => title.titleTypeId === 1);
        titlesArray[originalObjectIndex].title = newTitle       
        setAcceptedTitles(titlesArray)        
    }
        
    const handleTitleChange = (e) => {
        setNewTitle(e.target.value);
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }

    if (isEpisodeDataRequired) {
        return (<DataEntry
            id={id + '_DataEntry_audioVisual'}
            key={id}
            setStatusAction={setEpisodeFetchStatusAction} // Use the provided action for fetching results
            setOriginalResultAction={setEpisodeFetchResultsAction}
            requestSelector={selectEpisodeFetchRequest}
        />)
    }
    return (
        <div>            
            {showDataUpdate && (
                <DataUpdate
                    id={props.id + '_DataUpdate_audioVisual_Episode'}
                    key={dataUpdateKey}
                    setStatusAction={setEpisodeUpdateStatusAction}
                    requestSelector={selectEpisodeUpdateRequest}
                    setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                    onComplete={handleUpdateComplete}
                    episodeOnly={true}
                />
            )}
            <div className="episode-panel row" style={panelStyle}>
                <Toast ref={toast} />
                <div className="header" style={headerStyle}>
                    <div className="align-items-center d-flex" style={{ gap: '15px' }}>
                        <span style={programmeTitleStyle}>
                            {programmeTitle}
                            <CopyControl content={programmeTitle} />
                        </span>
                        <span style={codeStyle}>
                            Programme Code: {resultsData && resultsData.sapCode}

                        </span>
                        <CopyControl content={resultsData && resultsData.sapCode} />
                    </div>
                    {!isFilm && (
                        <Button icon="fa-light fa-xmark" className="close-button" style={closebuttonStyle} rounded onClick={showCancelConfirm} />
                    )}
                </div>
                <div className="content" style={contentStyle}>
                    <div className="row" style={rowHeightStyle}>
                        <div className='col-7'>
                            <div className='row'>
                                <p className="align-items-center mb-15 d-flex" style={{ gap: '15px' }}>
                                    {isEditingTitle ? (
                                        <div>
                                            <InputText value={newTitle} onChange={handleTitleChange} />
                                            <Button icon="pi pi-check" className="p-button-text" rounded onClick={handleSavePrimaryTitle} />
                                        </div>
                                    ) : (
                                        <div>
                                                <span style={episodeTitleStyle}>{isFilm ? programmeTitle : episodePrimaryTitle && toPascalCase(episodePrimaryTitle.title)}
                                                    <CopyControl content={isFilm ? programmeTitle : episodePrimaryTitle && toPascalCase(episodePrimaryTitle.title)} />
                                                </span>
                                            {!isFilm && (
                                                <Button icon="pi pi-pencil" className="p-button-text" rounded onClick={handleEditTitle} />
                                            )}
                                        </div>
                                    )}
                                    <span style={codeStyle}>Episode Code: {episodeData && episodeData.sapCode} </span>
                                    <CopyControl content={episodeData && episodeData.sapCode} />
                                </p>
                            </div>

                            <div className="row mb-4">
                                <div className="col-md-2">
                                    <div className="d-flex flex-column">
                                        <p className="label-input mb-1">Episode No.</p>
                                        <InputText id="episodeNumber" value={isFilm ? 'NA' : episodeData && episodeData.number} onChange={handleNumberChange} disabled={isFilm} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex flex-column ml-1">
                                        <p className="label-input mb-1">Series No.</p>
                                        <InputText id="seriesNumber" value={isFilm ? 'NA' : episodeData && episodeData.series} onChange={handleSeriesChange} disabled={isFilm} />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="d-flex flex-column ml-1">
                                        <p className="label-input mb-1">Duration</p>
                                        <InputText id="duration" value={episodeData && episodeData.durationMinutes} onChange={handleDurationChange} className="p-inputtext-sm  form-control" />

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex flex-column ml-1">
                                        <p className="label-input mb-1">Year of Production</p>
                                        <Calendar id="yearOfProduction" keyfilter="int" value={(episodeData && episodeData.yearOfProduction) ? new Date(episodeData.yearOfProduction, 0, 1) : null} onChange={handleYearChange} view="year" dateFormat="yy" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="d-flex flex-column ml-1">
                                        <p className="label-input mb-1">
                                            Episode IDA Number
                                            <CopyControl content={(episodeData && episodeData.idaCode)||''} />
                                        </p>
                                        <InputText id="episodeIDA" keyfilter="int" value={episodeData && episodeData.idaCode} onChange={handleIdaChange} />
                                    </div>
                                </div>
                            </div>
                            <div className='sub-section'>
                                <fieldset className="mt-2">
                                    <legend>History</legend>
                                </fieldset>
                            </div>
                            <div className='row mb-5'>
                                <div className="col-md-4">
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in" style={historyLabelStyle}>Created By:</span>
                                        <span className="details-dashboard-data-in" style={historyDataStyle}>
                                            {episodeData && episodeData.createdBy !== 'Default' ? episodeData.createdBy : ''}
                                        </span>
                                    </div>
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in" style={historyLabelStyle}>Date Created:</span>
                                        <span className="details-dashboard-data-in" style={historyDataStyle}>{episodeData && formatDateMonthTime(episodeData.createdOn)}</span>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in" style={historyLabelStyle}>Modified By:</span>
                                        <span className="details-dashboard-data-in" style={historyDataStyle}>
                                            {episodeData && episodeData.updatedBy !== 'Default' ? episodeData.updatedBy : ''}
                                        </span>
                                    </div>
                                    <div className="label-value">
                                        <span className="details-dashboard-label-in" style={historyLabelStyle}>Last Modified:</span>
                                        <span className="details-dashboard-data-in" style={historyDataStyle}>{episodeData && formatDateMonthTime(episodeData.updatedOn)}</span>
                                    </div>
                                </div>
                                <div className="col-md-1" />
                                <div className="col d-flex">
                                    <Button label="View History" icon="fa-solid fa-clock-rotate-left" raised disabled />
                                </div>
                            </div>
                        </div>
                        <div className="col-5 sub-section">
                            <OtherTitles altEpisodeTitles={altEpisodeTitles} isFilm={isFilm} languages={languages} />
                        </div>
                    </div>
                    <div>
                        <div className='sub-section mt-0'>
                            <fieldset className="mt-2">
                                <legend>Other Information</legend>
                            </fieldset>
                        </div>
                        <TabView className='custom-content'>
                            <TabPanel header="Contributors" leftIcon="fa-light fa-user-group mr-2">
                                <p className="m-0" style={otherInformationStyle}>
                                    <Contributions />
                                </p>
                            </TabPanel>
                            <TabPanel header="Transmissions" leftIcon="fa-light fa-satellite-dish mr-2">
                                <p className="m-0" style={otherInformationStyle}>
                                    <Transmissions />
                                </p>
                            </TabPanel>
                            <TabPanel header="Undistributed Royalty" leftIcon="fa-light fa-money-check-pen mr-2">
                                <p className="m-0" style={otherInformationStyle}>
                                    Undistributed Royalty
                                </p>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
                {isFilm ? null : footer}
            </div>
        </div>
    )
})