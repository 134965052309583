export function  toGbpCurrency(amount) {
    return amount.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'} )
}

export function toDate(date) {
    const submittedDate = new Date(date)

    return submittedDate.toLocaleDateString('en-GB')
}

export function toPascalCase(str) {
    return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '); // Join the words with a space
}

export function formatDateMonth(dateString) {
    const date = new Date(dateString);
    //if (isNaN(date)) {
    //    throw new Error('Invalid date string');
    //}

    const day = date.getDate();
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();

    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    }

    return `${day}${suffix} ${month} ${year}`;
}
export function formatDateMonthTime(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
    }

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes
    const month = date.toLocaleString('en-GB', { month: 'long' });
    const year = date.getFullYear();

    return `${hours}:${minutes} ${day}${suffix} ${month} ${year}`;
}

 export function moveEntryToTop (customlist, targetId) {
    // Find the index of the entry with id = targetId
    const index = customlist.findIndex(item => item.id === targetId);

    if (index !== -1) {
        // Remove the entry from its original position
        const [entry] = customlist.splice(index, 1);
        // Add the entry to the top of the list
        customlist.unshift(entry);
    }
    return customlist;
}

export function convertMinutesToDuration(minutes) {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60
    return `${hours}h ${mins}m`
}