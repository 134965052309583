import React, { useState, useRef, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'
import DataEntry from 'api/DataEntry/AudioVisual'
import DataUpdate from 'api/DataUpdate/AudioVisual'
import { Dropdown } from 'primereact/dropdown'
import { setProgrammeFetchStatusAction, setProgrammeFetchResultsAction, setProgrammeDraftResultsSapCodeAction, setProgrammeCreateStatusAction, setEpisodeFetchResultsAction, setProgrammeOriginalAggregateVersionAction, setEpisodeDraftResultsTransmissionsAction, clearEpisodeResultsTransmissionsFromDraftsAction, setProgrammeGuidAction, setProgrammeUpdateStatusAction, setProgrammeDraftResultsIsRadioAction, setProgrammeDraftResultsAnimeAction, setProgrammeDraftResultsTitlesAction, setProgrammeDraftResultsProgrammeTypeAction, setProgrammeDraftResultsLanguageAction, setProgrammeDraftResultsFormatTypeAction, setProgrammeDraftResultsProductionCompaniesAction, setProgrammeDraftResultsMasterIdaAction, clearProgrammeDraftResultsProgrammeTypeAction, clearProgrammeDraftResultsFormatTypeAction, clearProgrammeDraftResultsIsRadioAction, clearProgrammeDraftResultsAnimeAction, clearProgrammeDraftResultsProductionCompaniesAction, setProgrammeDraftResultsCountryAction, clearProgrammeDraftResultsCountryAction, clearProgrammeDraftResultsAction, clearProgrammeDraftResultsMasterIdaAction, clearProgrammeDraftResultsLanguageAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { selectProgrammeFetchRequest as selectprogrammeFetchRequest, selectEpisodeResultPresentation, selectEpisodeDraftHasDeletedTransmissions, selectUpdateStatus, selectUpdateErrorDetailMessage, selectProgrammeUpdateRequest, selectUpdateStatusMessage, selectProgrammeGuid, selectAudioVisualResultPresentation, selectAudioVisualResultOriginal, selectAudioVisualResultHasChanges, selectProgrammeCreateRequest, selectProgrammeCreateBody, selectAudioVisualResultPresentationTitles } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { toPascalCase, formatDateMonthTime } from 'modules'
import { InputText } from 'primereact/inputtext'
import { RadioButton } from 'primereact/radiobutton'
import { AutoComplete } from 'primereact/autocomplete'
import { MultiSelect } from 'primereact/multiselect'
import { ScrollPanel } from 'primereact/scrollpanel'
import EditTitles from 'components/Body/data-entry/AudioVisual/EditTitles'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { EpisodeDetails } from './EpisodeDetails'
import EpisodesList from './EpisodesList'
import { Toast } from 'primereact/toast'
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup'
import { SplitButton } from 'primereact/splitbutton'
import { push } from 'redux-first-history'
import DataCreate from 'api/DataCreate/AudioVisual'

// ReSharper disable once InconsistentNaming
export function AudioVisualDetails(props) {
    // This component now has a props.id and a props.className

    const location = useLocation()
    const toast = useRef(null)
    const episodeDetailsRef = useRef(null);


    /* Dispatch */
    const dispatch = useDispatch()
    const setAcceptedIsRadio = (isRad) => dispatch(setProgrammeDraftResultsIsRadioAction(isRad))
    const setAcceptedProgrammeType = (typeId) => dispatch(setProgrammeDraftResultsProgrammeTypeAction(typeId))
    const setAcceptedFormatType = (typeId) => dispatch(setProgrammeDraftResultsFormatTypeAction(typeId))
    const setAcceptedProductionCompanies = (companies) => dispatch(setProgrammeDraftResultsProductionCompaniesAction(companies))
    const setAcceptedCountry = (country) => dispatch(setProgrammeDraftResultsCountryAction(country))
    const setAcceptedMasterIda = (masterIda) => dispatch(setProgrammeDraftResultsMasterIdaAction(masterIda))
    const setAcceptedAnime = (anime) => dispatch(setProgrammeDraftResultsAnimeAction(anime))
    const setAcceptedLanguage = (language) => dispatch(setProgrammeDraftResultsLanguageAction(language))
    const setEpisodeDraftResultsTransmissions = (transmissions) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmissions))
    const clearAcceptedCountry = (countryId) => dispatch(clearProgrammeDraftResultsCountryAction(countryId))
    const clearProgrammeDraftResultsProgrammeType = () => dispatch(clearProgrammeDraftResultsProgrammeTypeAction())
    const clearProgrammeDraftResultsFormatType = () => dispatch(clearProgrammeDraftResultsFormatTypeAction())
    const clearProgrammeDraftResultsIsRadio = () => dispatch(clearProgrammeDraftResultsIsRadioAction())
    const clearProgrammeDraftResultsProductionCompanies = () => dispatch(clearProgrammeDraftResultsProductionCompaniesAction())
    const clearProgrammeDraftResultsMasterIda = () => dispatch(clearProgrammeDraftResultsMasterIdaAction())
    const clearProgrammeDraftResultsAnime = () => dispatch(clearProgrammeDraftResultsAnimeAction())
    const clearProgrammeDraftResultsLanguage = () => dispatch(clearProgrammeDraftResultsLanguageAction())
    const clearProgrammeEpisodeDraftResults = () => dispatch(clearProgrammeDraftResultsEpisodesAction())
    const clearProgrammeDraftResults = () => dispatch(clearProgrammeDraftResultsAction())
    const clearEpisodeDraftResultsTransmissions = () => dispatch(clearEpisodeResultsTransmissionsFromDraftsAction())

    const setBreadCrumb = (stack) => dispatch(setBreadCrumbStack(stack))
    const setProgrammeGuid = (guid) => dispatch(setProgrammeGuidAction(guid))
    const setProgrammeSapCode = (code) => dispatch(setProgrammeDraftResultsSapCodeAction(code))

    /* Selectors */
    const programmeGuid = useSelector(selectProgrammeGuid)
    const programmeDataOriginal = useSelector(selectAudioVisualResultOriginal)
    const resultsDataForEdit = useSelector(selectAudioVisualResultPresentation)
    const draftAvailable = useSelector(selectAudioVisualResultHasChanges)
    const deleteTransmissionAvailable = useSelector(selectEpisodeDraftHasDeletedTransmissions)
    const { languages, programmeTypes, programmeFormatTypes, productionCompanies, countries, animeTypes } = useSelector(selectAllAssets)
    const updateStatus = useSelector(selectUpdateStatus)
    const errorMessage = useSelector(selectUpdateErrorDetailMessage)
    var episodeData = useSelector(selectEpisodeResultPresentation)

    useEffect(() => {
        if (updateStatus.status === 'complete') {
            dispatch(setProgrammeUpdateStatusAction('idle'))
            dispatch(setProgrammeFetchResultsAction(resultsDataForEdit))
            dispatch(setEpisodeFetchResultsAction(episodeData))
            setShowDataUpdate(false)
            clearProgrammeDraftResults()
            clearProgrammeEpisodeDraftResults()            
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Update successful!', life: 3000 })
                         
        } else if (updateStatus.status === 'error') {
            dispatch(setProgrammeUpdateStatusAction('idle'))
            setShowDataUpdate(false)
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error: ${errorMessage}`, life: 3000 })            
        }
    }, [updateStatus, errorMessage])



    /* Params */
    const params = useParams()
    const episodeId = params.episodeId
    const isEpisodeRequired = params.episodeId !== undefined


    /* Declarations */
    const [showPanel, setShowPanel] = useState(false)
    const id = params.id || '*new*'
    const isProgrammeDataRequired = programmeGuid !== id
    //todo: this needs to be replaced with a fully realised store setup for programme create
    const [programmeCreateRequested, setProgrammeCreateRequested] = useState(false)




    let primaryTitle = null
    let altTitles = null
    let acceptCalled = false;

    const assetLanguages = languages

    const [filteredCompanies, setFilteredCompanies] = useState(null)
    const [showDataUpdate, setShowDataUpdate] = useState(false)
    const [dataUpdateKey, setDataUpdateKey] = useState(0)


    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    //get name of Production company Id from asset

    const getCompanyNameById = (companyId) => {
        const company = productionCompanies.find(pc => pc.id === companyId)
        return company ? { companyId: company.id, company: company.name } : null
    }

    const search = (event) => {
        setTimeout(() => {
            let _filteredCompanies

            if (!event.query.trim().length) {
                _filteredCompanies = productionCompanies.map(company => ({
                    companyId: company.id,
                    company: company.name
                }))
            } else {
                _filteredCompanies = productionCompanies
                    .filter(company => company.name.toLowerCase().startsWith(event.query.toLowerCase()))
                    .map(company => ({
                        companyId: company.id,
                        company: company.name
                    }))
            }

            setFilteredCompanies(_filteredCompanies)
        }, 250)
    }

    const handleProductionCompaniesChange = (e) => {
        const selectedCompanies = e.value
        const uniqueCompanies = []
        const originalIds = productionCompaniesOriginal.map(company => company.companyId)
        const originalNames = productionCompanies
            .filter(company => originalIds.includes(company.id))
            .map(company => ({
                companyId: company.id,
                company: company.name
            }));


        selectedCompanies.forEach(company => {
            // Check if the company is already in the uniqueCompanies list
            if (!uniqueCompanies.some(c => c.company === company.company)) {
                // If the company name exists in the original list, replace its id with the original id
                if (originalNames.includes(company.company)) {
                    const originalIndex = originalNames.indexOf(company.company)
                    uniqueCompanies.push({
                        companyId: originalIds[originalIndex],
                        company: company.company
                    })
                } else {
                    uniqueCompanies.push(company)
                }
            }
        })

        // Sort the uniqueCompanies based on the order of the original companies . necessary if user removes a company and adds it back in different order
        uniqueCompanies.sort((a, b) => {
            const indexA = originalNames.indexOf(a.company)
            const indexB = originalNames.indexOf(b.company)
            if (indexA === -1 && indexB === -1) return 0
            if (indexA === -1) return 1
            if (indexB === -1) return -1
            return indexA - indexB
        })

        if (JSON.stringify(uniqueCompanies) === JSON.stringify(originalNames)) {
            clearProgrammeDraftResultsProductionCompanies()
        } else {
            setAcceptedProductionCompanies(uniqueCompanies)
        }

    }

    const breadcrumbItem = {
        label: 'AV Details',
        path: location.pathname
    }

    /* Set Initialisation */
    setBreadCrumb([breadcrumbItem])

    if (isProgrammeDataRequired) {
        setProgrammeGuid(id)
    }

    /* Data */
    let primaryCountry = Object.values(resultsDataForEdit?.countries || {}).find(country => country.isPrimary === true)
    let secondaryCountries = Object.values(resultsDataForEdit?.countries || {}).filter(country => country.isPrimary === false)
    const { programmeTypeId, formatId, isRadio, productionCompanies: productionCompaniesDraft, sapCode } = resultsDataForEdit || {}
    const { programmeTypeId: programmeTypeIdOriginal, formatId: formatIdOriginal, isRadio: isRadioOriginal, productionCompanies: productionCompaniesOriginal } = programmeDataOriginal || {}
    let selectionValue = (isRadio ? 2 : 1) | programmeTypeId
    const availableTypes = programmeTypes.filter((t) => (t.consumedByTypes & selectionValue) > 0)
    const availableFormats = programmeFormatTypes.filter((t) => (t.consumedByProgrammeTypes & selectionValue & 3) > 0 && (t.consumedByProgrammeTypes & selectionValue & ~3))


    if (programmeTypeId > 0 && availableTypes.filter((t) => t.id === programmeTypeId).length === 0) {
        setAcceptedProgrammeType(0)
    }

    if (formatId > 0 && availableFormats.filter((t) => t.id === formatId).length === 0) {
        setAcceptedFormatType(0)
    }

    if (resultsDataForEdit && resultsDataForEdit.titles) {
        const titlesArray = Object.values(resultsDataForEdit.titles)
        primaryTitle = titlesArray.find(title => title.titleTypeId === 1)
        altTitles = titlesArray.filter(title => title.titleTypeId !== 1)
    }

    const handleUpdateComplete = () => {
        setShowDataUpdate(false)
    }

    if (isProgrammeDataRequired) {
        return (<DataEntry
            id={props.id + '_DataEntry_audioVisual'}
            key={props.id}
            setStatusAction={setProgrammeFetchStatusAction} // Use the provided action for fetching results
            setOriginalResultAction={setProgrammeFetchResultsAction}
            requestSelector={selectprogrammeFetchRequest}
        />)
    }


    var changeIsRadio = (radio) => radio === isRadioOriginal ? clearProgrammeDraftResultsIsRadio() : setAcceptedIsRadio(radio)

    const handlePrimaryCountryChange = (e, isPrimary) => {
        const selectedPrimaryCountry = e.value?.id
        const previousPrimaryCountry = primaryCountry?.countryId

        if (selectedPrimaryCountry !== previousPrimaryCountry) {
            if (secondaryCountries.some(country => country.countryId === selectedPrimaryCountry)) {
                setAcceptedCountry({ [previousPrimaryCountry]: { countryId: previousPrimaryCountry, isPrimary: false } })
            } else {
                clearAcceptedCountry(previousPrimaryCountry)
            }
            setAcceptedCountry({ [selectedPrimaryCountry]: { countryId: selectedPrimaryCountry, isPrimary: isPrimary } })
        }
    }

    const handleSecondaryCountryChange = (e) => {
        const selectedCountries = e.value
        const previousCountries = secondaryCountries.map(country => country.countryId)

        // Check if a country was added
        const addedCountries = selectedCountries.filter(country => !previousCountries.includes(country))
        if (addedCountries.length > 0) {
            const countriesToAdd = addedCountries.map(country => ({ countryId: country, isPrimary: false }))
            const countryDictionary = countriesToAdd.reduce((acc, country) => {
                acc[country.countryId] = country
                return acc
            }, {})
            setAcceptedCountry(countryDictionary)
        }

        // Check if a country was deleted
        const deletedCountries = previousCountries.filter(countryId => !selectedCountries.includes(countryId))
        if (deletedCountries.length > 0) {
            clearAcceptedCountry(deletedCountries)
        }
    }

    const onTestCreateProgrammeClick = (e) =>
        {
        e.preventDefault()
        setProgrammeCreateRequested(true)
        }

        
        if (programmeCreateRequested) {
            return (<DataCreate
                id={props.id + '_DataCreate_audioVisual'}
                key={props.id}
                setStatusAction={setProgrammeCreateStatusAction} 
                setSapCodeAction={setProgrammeDraftResultsSapCodeAction}
                onComplete={()=>setProgrammeCreateRequested(false)}
                requestSelector={selectProgrammeCreateRequest}
                requestBodySelector={selectProgrammeCreateBody}
            />)
        }

    //Accept and reject for Save cancellation popup

    const accept = () => {
        if (!draftAvailable) {
            navigateTo(`/AudioVisual`)
        }
        acceptCalled = true
        try {
            clearProgrammeDraftResults()
            clearProgrammeEpisodeDraftResults()           
            toast.current.show({
                severity: 'info',
                summary: 'Confirmed',
                detail: 'Programme Changes aborted',
                life: 3000
            })
            navigateTo(`/AudioVisual`)
        } catch (error) {
            console.error('Error in accept function:', error)
        }
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Programme changes as Draft', life: 3000 })
    }

    //Cancel Save 

    const showCancelConfirm = (event) => {
        if (draftAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You have unsaved changes. Do you want to proceed to cancel?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                accept,
                reject
            })
        }
        else {
            navigateTo(`/AudioVisual`)
        }
    }

    //On direct click of Accept in split button
    const splitSave = (event) => {
        //transmission delete confirmation
        if (deleteTransmissionAvailable) {
            confirmPopup({
                target: event.currentTarget,
                message: 'You are about to delete Transmissions. Do you want to continue?',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                accept: acceptSave,
                reject: rejectTransmissionChange
            })
        }
        else {
            acceptSave()
        }


    }

    const acceptSave = () => {
        if (!validateChanges()) {
            return
        }
        handleUpdateTrigger().then(() => {
            //navigateTo(`/AudioVisual`);
        }).catch(error => {
            // Handle error if needed
            console.error('Update failed:', error);
        })
    }

    const rejectTransmissionChange = () => {
        clearEpisodeDraftResultsTransmissions()
        dispatch(setEpisodeDraftResultsTransmissions(episodeData?.transmissions))

    }

    const validateChanges = () => {
        let isValid = true;

        if (resultsDataForEdit) {
            // Program Main title should not be empty
            if (!primaryTitle || !primaryTitle.title) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Title is required' })
                isValid = false
            }
            // Master IDA ID should be numeric
            if (resultsDataForEdit.idAid && isNaN(resultsDataForEdit.idAid)) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Master IDA ID should be numeric' })
                isValid = false
            }
            // Programme Type should be selected
            if (!programmeTypeId) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Programme Type is required' })
                isValid = false
            }            
            // Format should be selected
            if (!formatId) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Format is required' })
                isValid = false
            }
            else {
                if (formatId === 1 || formatId === 5) {
                    if (!resultsDataForEdit.animeOrLive) {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Anime/Live is required for Film Type' })
                        isValid = false
                    }
                }
            }
            // Language should be selected
            if (!resultsDataForEdit.languageId) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Language is required' })
                isValid = false
            }
            // Primary Country should be selected
            if (!primaryCountry || !primaryCountry.countryId) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Primary Country is required' })
                isValid = false
            }
            // Perform validation in EpisodeDetails
            if (episodeDetailsRef.current) {
                isValid = isValid && episodeDetailsRef.current.validateEpisodeDetails()
            }

        }

        return isValid;
    }

    //handle update by incrementing the key
    const handleUpdateTrigger = () => {
        return new Promise((resolve, rej) => {
            try {
                dispatch(setProgrammeUpdateStatusAction('idle'))
                setShowDataUpdate(false)
                setDataUpdateKey(prevKey => prevKey + 1) // Increment the key to force re-render
                setShowDataUpdate(true)
                resolve();
            } catch (error) {
                rej(error)
            }
        })
    }


    //Accept Split Buttom Items
    const saveModalItems = [
        {
            label: 'Save',
            icon: 'fa-light fa-check',
            command: (event) => {
                if (deleteTransmissionAvailable) {
                    confirmPopup({
                        target: event.currentTarget,
                        message: 'You are about to delete Transmissions. Do you want to continue?',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: acceptSave,
                        reject: rejectTransmissionChange
                    })
                }
                else {
                    if (!validateChanges()) {
                        return
                    }
                    handleUpdateTrigger()
                }
                
            }
        },
        {
            label: 'Save and Exit',
            icon: 'fa-light fa-check',
            command: () => {
                if (!validateChanges()) {
                    return
                }
                handleUpdateTrigger().then(() => {
                    navigateTo(`/AudioVisual`)
                }).catch(error => {
                    console.error('Update failed:', error);
                })
            }
        }
    ]





    return (
        <div className='application-content d-flex flex-column flex-grow-1' >
            {showDataUpdate && (
                <DataUpdate
                    id={props.id + '_DataUpdate_audioVisual'}
                    key={dataUpdateKey}
                    setStatusAction={setProgrammeUpdateStatusAction}
                    requestSelector={selectProgrammeUpdateRequest}
                    setAggregateAction={setProgrammeOriginalAggregateVersionAction}
                    onComplete={handleUpdateComplete}
                    episodeOnly={false}
                />
            )}
            <div className='container-fluid d-flex flex-column flex-grow-1'>
                <div className='row d-flex flex-grow-1'>
                    <div className='col-3 d-flex flex-column flex-grow-1'>
                        <div className='app-sidebar flex-grow-1'>
                            <div className='details-header'>
                                <div className="header mb-2"><span class="title">Prog. Code: {resultsDataForEdit && resultsDataForEdit.sapCode ? resultsDataForEdit.sapCode : 'Pending'}</span></div>
                                <div className='programme-title'>{primaryTitle && toPascalCase(primaryTitle.title)}</div>
                                <fieldset>
                                    <legend>Other Titles</legend>
                                    <div className="other-titles-content">
                                        <ScrollPanel className="mt-1" style={{ height: '60px' }}>
                                            {altTitles && (altTitles.map((title, index) => (
                                                <div key={index} className="mb-2"> <Tag value={title.languageName && title.languageName.substring(0, 2).toLowerCase()} className="mr-2"></Tag>{toPascalCase(title.title)}</div>
                                            )))}
                                        </ScrollPanel>
                                        <Button label="Edit Titles" icon="fa-light fa-pen" className="sage mt-4 button-left-align " onClick={() => setShowPanel(true)} raised />
                                        <EditTitles showPanel={showPanel} setShowPanel={setShowPanel} primarytitle={primaryTitle} languages={assetLanguages} selectTitles={selectAudioVisualResultPresentationTitles} setTitlestoStore={setProgrammeDraftResultsTitlesAction } />
                                    </div>
                                </fieldset>
                            </div>
                            <div className="details-content mt-4">
                                <fieldset className="mt-2">
                                    <legend>Details</legend>
                                    <ScrollPanel className="ml-3 mt-1" >
                                        <div className="row mb-2">
                                            <div className="col-md-8 d-flex align-items-center">
                                                <div id="results-text">
                                                    <p className="label-input mb-1">Master IDA ID</p>
                                                    <div className="d-flex align-items-center">
                                                        <InputText id="masterIDAid" keyfilter="int" value={resultsDataForEdit && resultsDataForEdit.idAid} onChange={(e) => (programmeDataOriginal.idAid === null && e.target.value === "") || (e.target.value === programmeDataOriginal.idAid) ? clearProgrammeDraftResultsMasterIda() : setAcceptedMasterIda(e.target.value)} className="p-inputtext-sm av-radio-option form-control mr-2" />
                                                        <Button icon="pi pi-copy" className="p-button-text" rounded onClick={() => navigator.clipboard.writeText(resultsDataForEdit?.idAid || '')} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-md-2 mt-3 ml-3">
                                                <label className="label-input mb-1">Type</label>
                                                <div className="d-flex align-items-center mt-2">
                                                    <RadioButton inputId="av" name="type" value={false} checked={!isRadio} onChange={(e) => changeIsRadio(e.value)} />
                                                    <label htmlFor="av" className="mr-2 av-radio-option">AV</label>
                                                    <RadioButton inputId="radio" name="type" value={true} checked={isRadio} onChange={(e) => changeIsRadio(e.value)} />
                                                    <label htmlFor="radio" className="mr-2 av-radio-option">Radio</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-4">
                                                <p className="label-input mb-1">Programme Type</p>
                                                <Dropdown id="programmeType" value={availableTypes.find(type => type.id === programmeTypeId)} options={availableTypes} optionLabel="name" optionValue="id" onChange={(e) => e.value === programmeTypeIdOriginal ? clearProgrammeDraftResultsProgrammeType() : setAcceptedProgrammeType(e.value?.id)}
                                                    itemTemplate={(option) => (<div>{option.sapCode} - {option.name}</div>)}
                                                    valueTemplate={(option) => option?.name} className="custom-full-width" />
                                            </div>
                                            <div className="col-md-4">
                                                <p className="label-input mb-1">Format</p>
                                                <Dropdown
                                                    id="format" value={availableFormats.find(format => format.id == formatId)} options={availableFormats} optionLabel="name" optionValue="id" onChange={(e) => e.value === formatIdOriginal ? clearProgrammeDraftResultsFormatType() : setAcceptedFormatType(e.value?.id)}
                                                    itemTemplate={(option) => (<div>{option.sapCode} - {option.name}</div>)}
                                                    valueTemplate={(option) => option?.name} className="custom-full-width" />
                                            </div>

                                            {!isRadio && (
                                                <div className="col-md-4">
                                                    <p className="label-input mb-1">Animation / Live</p>
                                                    {/*Use Asset loaded values for options */}
                                                    <Dropdown
                                                        id="anime"
                                                        value={resultsDataForEdit && resultsDataForEdit.animeOrLive}
                                                        options={animeTypes}
                                                        optionLabel="name"
                                                        optionValue="id"
                                                        onChange={(e) => e.value === programmeDataOriginal.animeOrLive ? clearProgrammeDraftResultsAnime() : setAcceptedAnime(e.value)}                                                        
                                                        placeholder={resultsDataForEdit && resultsDataForEdit.animeOrLive && animeTypes.find(type => type.id === resultsDataForEdit.animeOrLive)?.name}
                                                        className="custom-full-width"
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-md-6">
                                                <p className="mb-0 label-input mb-1">Language</p>
                                                <div className="d-flex align-items-center">
                                                    {/*Use Asset loaded values for options */}
                                                    <Dropdown id="language" value={resultsDataForEdit && resultsDataForEdit.languageId} filter options={assetLanguages} onChange={(e) => e.value === programmeDataOriginal.languageId ? clearProgrammeDraftResultsLanguage() : setAcceptedLanguage(e.value)} placeholder={resultsDataForEdit && resultsDataForEdit.languageName} className="custom-full-width" optionLabel="name"
                                                        optionValue="id" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="label-input mb-1">Primary Country</p>
                                                <Dropdown id="primaryCountry" value={countries.find(country => country.id == primaryCountry?.countryId)} filter options={countries} onChange={(e) => handlePrimaryCountryChange(e, true)} className="custom-full-width" optionLabel="name" optionValue="id" />
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-12">
                                                <p className="mb-0 label-input mb-1">Secondary Countries</p>
                                                <div className="d-flex align-items-center">
                                                    <MultiSelect value={secondaryCountries.map(country => country.countryId)} options={countries.filter(country => country.id !== primaryCountry?.countryId)} onChange={handleSecondaryCountryChange} optionLabel="name" optionValue="id"
                                                        filter placeholder="Select Countries" className="expanding w-100 av-secondarycountry" display="chip" disabled={!primaryCountry?.countryId} readOnly={!primaryCountry?.countryId} />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-md-12">
                                                <label htmlFor="productionCompanies" className="label-input mb-1">Production Companies</label>

                                                <div id="details-search" className="p-inputgroup flex-1">
                                                    <AutoComplete field="company" multiple value={productionCompaniesDraft && productionCompaniesDraft.map(pc => getCompanyNameById(pc.companyId))} suggestions={filteredCompanies} completeMethod={search} onChange={handleProductionCompaniesChange} className="av-production-company" display="chips" />
                                                    <Button icon="pi pi-search" />
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollPanel>
                                </fieldset>
                            </div>
                            <div className="app-footer">
                                <SplitButton className='green mr-2 mt-2' label="Save" icon="fa-light fa-check" onClick={splitSave} dropdownIcon="fa-light fa-chevron-down" model={saveModalItems} />
                                <Button label="Cancel" icon="fa-light fa-xmark" className="yellow mt-2 mr-3 " raised onClick={showCancelConfirm} ></Button>
                                {/* <Button label="Delete" icon="fa-light fa-trash-can" className="mt-2 orange" raised disabled />*/}

                            </div>
                        </div>
                    </div>
                    <div className='col-9 d-flex flex-column flex-grow-1'>
                        <div className='app-content flex-grow-1'>
                            <Toast ref={toast} />
                            <ConfirmPopup />
                            <div className='row'>
                                <div className="col-12 container">
                                    <div className='details-dashboard row'>
                                        <div className='details-dashboard-cpt text-center col-1 mt-1'>
                                            <span className='details-dashboard-label mt-4'>Total Episodes</span>
                                            <span className='details-dashboard-data-lg t-1'>{resultsDataForEdit && resultsDataForEdit.episodesCount}</span>
                                        </div>
                                        <div className='details-dashboard-cpt col-7 mb-1'>
                                        </div>
                                        <div className='details-dashboard-cpt col-2'>
                                            <span className="title lh-lg mb-1">History</span>
                                            <ul>
                                                <li>
                                                    <span className="details-dashboard-label-in">Created by:</span>
                                                    <span className="details-dashboard-data-in">{resultsDataForEdit && resultsDataForEdit.createdBy}</span>
                                                </li><li>
                                                    <span className="details-dashboard-label-in">Date Created:</span>
                                                    <span className="details-dashboard-data-in">{resultsDataForEdit && formatDateMonthTime(resultsDataForEdit.createdOn)}</span>
                                                </li><li>
                                                    <span className="details-dashboard-label-in">Modified by:</span>
                                                    <span className="details-dashboard-data-in">{resultsDataForEdit && resultsDataForEdit.updatedBy}</span>
                                                </li><li>
                                                    <span className="details-dashboard-label-in">Last Modified:</span>
                                                    <span className="details-dashboard-data-in">{resultsDataForEdit && formatDateMonthTime(resultsDataForEdit.updatedOn)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='details-dashboard-cpt text-center col-2'>
                                            <br />
                                            <br />
                                            <Button label="View History" icon="fa-solid fa-clock-rotate-left" className="ml-3" raised />
                                        </div>
                                    </div>
                                    {isEpisodeRequired ? <EpisodeDetails toast={toast} ref={episodeDetailsRef} id={props.id} episodeId={episodeId} resultsData={resultsDataForEdit} programmePrimaryTitle={primaryTitle} otherProgrammeTitles={altTitles} languages={assetLanguages} /> : <div className='alcs-panel'><EpisodesList id={props.id} /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
