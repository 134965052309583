export const avDataEntryInitialState = {
    guid: '',
    status: 'idle',
    updateStatus: 'idle'
}

export const avDataEntryReducer = (state = avDataEntryInitialState, action) => {
    switch (action.type) {
        case 'DATAENTRY_AUDIOVISUAL_SET_FETCH_STATUS_ACTION':
            return {
                ...state,
                status: action.payload
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_CREATE_STATUS_ACTION':
            return {
                ...state,
                status: action.payload
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_FETCH_RESULT_ACTION':
            return {
                ...state,
                [state.guid]: {
                    original: action.payload
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_ORIGINAL_VERSION_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    original: {
                        ...(state[state.guid]?.original || {}),
                        version: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        ...action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_TITLES_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        titles: action.payload
                    },
                    original: state[state.guid]?.original // To preserve the original property
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ISRADIO_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        isRadio: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_MASTERIDA_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        idAid: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ANIME_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        animeOrLive: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_LANGUAGE_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        languageId: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PROGRAMMETYPE_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        programmeTypeId: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_FORMATTYPE_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        formatId: action.payload
                    }
                }
            }

        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_SAPCODE_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        sapCode: action.payload.sapCode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PRODUCTIONCOMPANIES_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        productionCompanies: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_COUNTRY_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        countries: {
                            ...(state[state.guid]?.draft?.countries || {}),
                            ...action.payload
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_EPISODES_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    draft: {
                        ...(state[state.guid]?.draft || {}),
                        episodes: {
                            ...(state[state.guid]?.draft?.episodes || {}),
                            ...action.payload
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ACTION':
            {
                const {
                    draft,
                    ...stateWithoutDraft
                } = state[state.guid] || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...stateWithoutDraft
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PROGRAMMETYPE_ACTION':
            {
                const {
                    programmeTypeId,
                    ...draftWithoutProgrammeTypeId
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutProgrammeTypeId
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_FORMATTYPE_ACTION':
            {
                const {
                    formatId,
                    ...draftWithoutFormatId
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutFormatId
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ISRADIO_ACTION':
            {
                const {
                    isRadio,
                    ...draftWithoutIsRadio
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutIsRadio
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_MASTERIDA_ACTION':
            {
                const {
                    idAid,
                    ...draftWithoutidAid
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutidAid
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ANIME_ACTION':
            {
                const {
                    animeOrLive,
                    ...draftWithoutAnime
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutAnime
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_LANGUAGE_ACTION':
            {
                const {
                    languageId,
                    ...draftWithoutLanguage
                } = state[state.guid]?.draft || {}
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutLanguage
                    }
                }
            }

        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PRODUCTIONCOMPANIES_ACTION':
            {
                const {
                    productionCompanies,
                    ...draftWithoutProductionCompanies
                } = state[state.guid]?.draft || {};
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: draftWithoutProductionCompanies
                    }
                }
            }

        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_COUNTRY_ACTION':
            {
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        draft: {
                            ...(state[state.guid]?.draft || {}),
                            countries: {
                                ...(state[state.guid]?.draft?.countries || {}),
                                ...(Array.isArray(action.payload) ? action.payload.reduce((acc, countryId) => {
                                    acc[countryId] = {
                                        ...(state[state.guid]?.draft?.countries?.[countryId] || {}),
                                        delete: true
                                    };
                                    return acc;
                                }, {}) : {
                                    [action.payload]: {
                                        ...(state[state.guid]?.draft?.countries?.[action.payload] || {}),
                                        delete: true
                                    }
                                })
                            }
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_EPISODES_ACTION':
            {
                const {
                    draft,
                    ...episodeWithoutDraft
                } = state[state.guid]?.episode || {};
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: episodeWithoutDraft
                    }
                }

            }
        case 'DATAENTRY_AUDIOVISUAL_SET_GUID':
            return {
                ...state,
                guid: action.payload
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_STATUS_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        status: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODEUPDATE_STATUS_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        status: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_RESULT_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        original: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODEGUID':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        guid: action.payload
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            ...action.payload
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_TRANSMISSIONS_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            transmissions: {
                                ...(state[state.guid]?.episode?.draft?.transmissions || {}),
                                ...action.payload
                            }
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_TITLE_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            titles: action.payload
                        }
                    }



                }
            }

        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_TRANSMISSIONS_ACTION':
            {
                const transmissions = { ...state[state.guid]?.episode?.draft?.transmissions }
                if (!transmissions[action.payload]) {
                    transmissions[action.payload] = { delete: true };
                } else {
                    transmissions[action.payload].delete = true;
                }

                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: {
                            ...state[state.guid]?.episode,
                            draft: {
                                ...(state[state.guid]?.episode?.draft || {}),
                                transmissions: transmissions
                            }
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_RESULT_TITLE_ACTION':
            {
                const titles = { ...state[state.guid]?.episode?.draft?.titles }
                if (!titles[action.payload]) {
                    titles[action.payload] = { delete: true };
                } else {
                    titles[action.payload].delete = true;
                }

                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: {
                            ...state[state.guid]?.episode,
                            draft: {
                                ...(state[state.guid]?.episode?.draft || {}),
                                titles: titles
                            }
                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_PROGRAMMEUPDATE_STATUS_ACTION':
            return {
                ...state,
                updateStatus: action.payload
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_DURATION_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            durationMinutes: action.payload

                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_DURATION_ACTION':
            {
                const draftCopy = { ...state[state.guid]?.episode?.draft }
                delete draftCopy.durationMinutes;

                /* Check if draftCopy is empty*/
                const isDraftEmpty = Object.keys(draftCopy).length === 0

                const newEpisode = {
                    ...state[state.guid]?.episode,
                    draft: isDraftEmpty ? undefined : draftCopy
                }
                if (isDraftEmpty) {
                    delete newEpisode.draft;
                }
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: newEpisode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_YEAR_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            yearOfProduction: action.payload

                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_YEAR_ACTION':
            {
                const draftCopy = { ...state[state.guid]?.episode?.draft }
                delete draftCopy.yearOfProduction

                /* Check if draftCopy is empty*/
                const isDraftEmpty = Object.keys(draftCopy).length === 0

                const newEpisode = {
                    ...state[state.guid]?.episode,
                    draft: isDraftEmpty ? undefined : draftCopy
                }
                if (isDraftEmpty) {
                    delete newEpisode.draft;
                }
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: newEpisode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_IDA_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            idaCode: action.payload

                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_IDA_ACTION':
            {
                const draftCopy = { ...state[state.guid]?.episode?.draft }
                delete draftCopy.idaCode

                /* Check if draftCopy is empty*/
                const isDraftEmpty = Object.keys(draftCopy).length === 0

                const newEpisode = {
                    ...state[state.guid]?.episode,
                    draft: isDraftEmpty ? undefined : draftCopy
                }
                if (isDraftEmpty) {
                    delete newEpisode.draft;
                }
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: newEpisode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_SERIES_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            series: action.payload

                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_SERIES_ACTION':
            {
                const draftCopy = { ...state[state.guid]?.episode?.draft }
                delete draftCopy.series

                /* Check if draftCopy is empty*/
                const isDraftEmpty = Object.keys(draftCopy).length === 0

                const newEpisode = {
                    ...state[state.guid]?.episode,
                    draft: isDraftEmpty ? undefined : draftCopy
                }
                if (isDraftEmpty) {
                    delete newEpisode.draft;
                }
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: newEpisode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_NUMBER_ACTION':
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: {
                        ...state[state.guid]?.episode,
                        draft: {
                            ...(state[state.guid]?.episode?.draft || {}),
                            number: action.payload

                        }
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_NUMBER_ACTION':
            {
                const draftCopy = { ...state[state.guid]?.episode?.draft }
                delete draftCopy.number

                /* Check if draftCopy is empty*/
                const isDraftEmpty = Object.keys(draftCopy).length === 0

                const newEpisode = {
                    ...state[state.guid]?.episode,
                    draft: isDraftEmpty ? undefined : draftCopy
                }
                if (isDraftEmpty) {
                    delete newEpisode.draft;
                }
                return {
                    ...state,
                    [state.guid]: {
                        ...state[state.guid],
                        episode: newEpisode
                    }
                }
            }
        case 'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_TRANSMISSION_ACTION': {

            const draftCopy = { ...state[state.guid]?.episode?.draft }
            delete draftCopy.transmissions;

            const isDraftEmpty = Object.keys(draftCopy).length === 0
            const newEpisode = {
                ...state[state.guid]?.episode,
                draft: isDraftEmpty ? undefined : draftCopy
            }
            return {
                ...state,
                [state.guid]: {
                    ...state[state.guid],
                    episode: newEpisode
                }
            }
        }


        default:
            return state
    }
}