import { React } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import tv from 'assets/images/Tv.svg';
import audio from 'assets/images/Audio.svg';
import { selectTvFilmRadioFetchResults, selectTvFilmRadioFetchRequest, selectAvCurrentPage, selectAvTotalPages, selectTvFilmRadioAvRows, selectTvFilmRadioAvFirst, selectTvFilmRadioFetchStatus, selectAvFilter, selectAvSort } from 'components/Body/Search/SearchResultsPanels/store/selectors'
import { setTvFilmRadioFetchStatusAction, setTvFilmRadioFetchResultsAction, setAvTotalPagesAction, setAvFirstAction, setAvRowsAction, setAvCurrentPageAction, setAvExcelExportAction, setAvCsvExportAction, setAvFilterAction, setAvSortAction } from './store/actions'
import { selectAvApiHost } from 'globalSelectors' 
import CommonGrid from 'components/common/CommonGrid'
import 'primeicons/primeicons.css'
import { Button } from 'primereact/button';
import { push } from 'redux-first-history';
import { FilterMatchMode } from 'primereact/api'



const csvDownloadPathSuffix = '/search/csvexport?searchTerm='
const csvDownloadName = 'AudioVisual.csv'
const excelDownloadPathSuffix = '/search/excelexport?searchTerm='
const excelDownloadName = 'AudioVisual.xlsx'
export function AudioVisual(props) {
    const searchAvResults = useSelector(selectTvFilmRadioFetchResults)
    const currentPage = useSelector(selectAvCurrentPage)
    const filter = useSelector(selectAvFilter)
    const sort = useSelector(selectAvSort)
    const originalQuery = useSelector(selectTvFilmRadioFetchRequest)
    const avApiHost = useSelector(selectAvApiHost)

    const dispatch = useDispatch()

    const csvDownloadPath = avApiHost + csvDownloadPathSuffix + originalQuery.term
    const excelDownloadPath =avApiHost + excelDownloadPathSuffix + originalQuery.term
    let avResultList = []
    let avSearchResultsCount = 0

    if (searchAvResults) {
        avResultList = searchAvResults['results']
        avSearchResultsCount = searchAvResults['total']

    }

    const displayType = function (rowData) {
        switch (rowData.iconId) {
            case '1':
                return <img src={audio} alt=""></img>
            default:
                return <img src={tv} alt=""></img>
        }
    }

    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }

    const handleEpisodeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}/${rowData.episodeId}`)
    }

    const displayEpisode = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                <Button className="white ml-2" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleEpisodeNavigation(rowData)} />
            </div>
        )
    }

    const displayEpisodeTitle = function (rowData) {
        return displayEpisode(rowData, 'episodeTitle')
    }

    const displayEpisodeCode = function (rowData) {
        return displayEpisode(rowData, 'episodeCode')
    }

    const handleProgrammeNavigation = (rowData) => {
        navigateTo(`/AudioVisual/${rowData.programmeId}`)
    }

    const displayProgramme = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />
                <Button className="white ml-1" icon="fa-light fa-arrow-up-right" rounded onClick={() => handleProgrammeNavigation(rowData)} />
            </div>
        )
    }

    const displayProgrammeTitle = function (rowData) {
        return displayProgramme(rowData, 'programmeTitle')
    }

    const displayProgrammeCode = function (rowData) {
        return displayProgramme(rowData, 'programmeCode')
    }

    const displayIdaCode = function (rowData) {
        return displayIda(rowData, 'episodeIda')
    }
    const displayIda = function (rowData, field) {
        return (
            <div id={props.id + '_avList_commonGrid_' + field} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <Button icon="pi pi-copy" className="white ml-2" rounded onClick={() => navigator.clipboard.writeText(rowData[field])} />

            </div>
        )

    }
    const displayActions = function (rowData) {
        return (
            <div id={props.id + '_avList_commonGrid_actions'}>
                {/*<img src={people} width="16" alt="" />*/}
                {/*<img src={edit} width="16" alt="" />*/}
                {/*<img src="https://www.codestone.net/wp-content/uploads/2018/12/cs-logo.jpg" width="16" alt="" />*/}
                {/*<img src={smiley} width="16" alt="" />*/}
            </div>
        )
    }

    const avColumnDefinitions = [

        { title: 'Type', mapsToField: 'iconId', template: displayType },
        { title: 'Programme Code', mapsToField: 'programmeCode', filter: true, template: displayProgrammeCode, sortable: true },
        { title: 'Programme Title', mapsToField: 'programmeTitle', template: displayProgrammeTitle, filter: true, sortable: true },
        { title: 'Episode Code', mapsToField: 'episodeCode', filter: true, template: displayEpisodeCode, sortable: true },
        { title: 'Episode Title', mapsToField: 'episodeTitle', filter: true, template: displayEpisodeTitle, sortable: true },
        { title: 'Episode No', mapsToField: 'episodeNumber', filter: true, sortable: true },
        { title: 'Series No', mapsToField: 'episodeSeries', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'Year Produced', mapsToField: 'episodeYear', filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { title: 'IDA No.', mapsToField: 'episodeIda', template: displayIdaCode, filterMatchMode: FilterMatchMode.EQUALS, filter: true, sortable: true },
        { expand: true, title: 'Contributors', mapsToField: 'Contributors', sortable: false, filter: false, template: displayActions }
    ]

    return (
        <div id={props.id + '_avList'} >
            <CommonGrid
                id={props.id + '_avList_commonGrid'}
                keyColumnName="id"
                data={avResultList}
                totalCount={avSearchResultsCount}
                emptyMessage="No Results"
                columnDefinitions={avColumnDefinitions}
                selectTotalPages={selectAvTotalPages}
                selectFirst={selectTvFilmRadioAvFirst}
                selectRows={selectTvFilmRadioAvRows}
                fetchStatusSelector={selectTvFilmRadioFetchStatus}
                setTotalPagesAction={setAvTotalPagesAction}
                setCurrentPageAction={setAvCurrentPageAction}
                setFirstAction={setAvFirstAction}
                setRowsAction={setAvRowsAction}
                setFilterAction={setAvFilterAction}
                setSortAction={setAvSortAction}
                fetchResultsAction={setTvFilmRadioFetchResultsAction}
                fetchStatusAction={setTvFilmRadioFetchStatusAction}
                requestSelector={selectTvFilmRadioFetchRequest}
                currentPage={currentPage}
                filter={filter}
                sort={sort}
                csvDownloadPath={csvDownloadPath}
                csvDownloadName={csvDownloadName}
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
            />

        </div>
    )
}
