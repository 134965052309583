import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'redux-first-history'; // Import push action creator
import { Dropdown } from 'primereact/dropdown'
import  menuItems  from 'components/Header/PlusMenuOptions.json'

// ReSharper disable once InconsistentNaming
const PlusMenu = (props) => {
    const menu = useRef(null)
    const dispatch = useDispatch()

    const handleMenuItemClick = (event) => {        
        event.preventDefault()
        dispatch(push(event.value)) // Dispatch push action with the route
        
    }

    // Most of the data in this component is the menu, which is written in PlusMenu.json
    //Option Group Label removed temporarily.  Will add back in when I have more than one group
    return (
        <span id={props.id} className={props.className}>
            <Dropdown
                options={menuItems}
                optionLabel="label"                
                placeholder="Add/New"
                dropdownIcon="pi pi-angle-down"
                onChange={(e) => handleMenuItemClick(e)}
                className="icon-left fas fa-plus green"
                panelClassName="green"           
            >               
            </Dropdown>
           
        </span>
    )
}
export default PlusMenu