import { useState, useRef } from 'react';
import { useRefAsState } from 'modules/React.Extensions'
import { useDispatch, useSelector } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'


const mergeEpisodeChanges = (original, draft) => {
    if (original) {
        const mergedTransmissions = {
            ...original.transmissions,
            ...draft?.transmissions || []
        }
        const mergedTitles = draft?.titles || original?.titles || {}           
        
        // Filter out transmissions with delete tag set to true
        const filteredTransmissions = Object.entries(mergedTransmissions)
            .filter(([key, transmission]) => !transmission.delete)
            .reduce((obj, [key, transmission]) => {
                obj[key] = transmission
                return obj
            }, {})
     

        return {
            ...original,
            ...draft,
            titles: mergedTitles,
            transmissions: filteredTransmissions
        }
    }
    return {}
}


const mergeChanges = (original, draft, episodeOriginal, episodeDraft) => {
    if (original) {
        const mergedTitles = draft?.titles || original?.titles || {}   
        const mergedCountries = {
            ...original.countries,
            ...draft?.countries || []
        }
        const draftEpisode = mergeEpisodeChanges(episodeOriginal, episodeDraft);

        // Merge the draft episode into the original episodes list
        const mergedEpisodes = Object.entries(original.episodes)
            .reduce((obj, [key, episode]) => {
                obj[key] = episode.id === draftEpisode.id
                    ? draftEpisode
                    : episode
                return obj
            }, {})
        // If the draft episode is new, add it to the list
        if (!Object.values(original.episodes).some(episode => episode.id === draftEpisode.id)) {
            mergedEpisodes[episodeDraft.id] = episodeDraft;
        }      

        const filteredCountries = Object.entries(mergedCountries)
            .filter(([key, country]) => !country.delete)
            .reduce((obj, [key, country]) => {
                obj[key] = country
                return obj
            }, {})
        
        const filteredEpisodes = Object.entries(mergedEpisodes)
            .filter(([key, episode]) => !episode.delete)
            .reduce((obj, [key, episode]) => {
                obj[key] = episode
                return obj
            }, {})

        return {
            ...original,
            ...draft,
            titles: mergedTitles,
            countries: filteredCountries,
            episodes: filteredEpisodes
        }
    }
    return {}
}

const transformDraftToProgrammeInfo = (draft, original, episodeOriginal, episodeDraft) => {

    //TODO: Need to send only the changed fields; delta

    var presentation = mergeChanges(original, draft, episodeOriginal, episodeDraft)

    // Check if formatId is 1 or 5
    const isFilm = presentation.formatId === 1 || presentation.formatId === 5  

    const transformed = {
        AnimeOrLive: presentation?.animeOrLive?.id || presentation?.animeOrLive || "",
        FormatId: presentation.formatId,
        IsRadio: presentation.isRadio,
        LanguageId: presentation.languageId,
        ProgrammeTypeId: presentation.programmeTypeId,
        SapCode: presentation.sapCode,
        Comment: presentation.comment,
        IdAId: presentation.idAid,
        TotalEpisodes: presentation.totalEpisodes,
        ProductionCompanies: Object.values(presentation.productionCompanies).map(company => company.companyId),
        Countries: Object.values(presentation.countries).map(country => ({
            CountryId: country.countryId,
            IsPrimary: country.isPrimary
        })),
        Titles: Object.values(presentation.titles).map(title => ({            
            Title: title.title,
            LanguageId: title.languageId,
            TitleTypeId: title.titleTypeId
        })),
        EpisodeInfos: isFilm ? Object.values(presentation.episodes).map(episode => ({
            Id: episode.id,
            DurationMinutes: episode.durationMinutes,
            IdaCode: episode.idaCode,
            YearOfProduction: episode.yearOfProduction,
            Number: isFilm ? 0 : episode.number,
            Series: isFilm ? 0 : episode.series,
            SapCode: episode.sapCode,
            Comments: episode.comments,
            ProgrammeSapCode: episode.programmeSapCode,
            Titles: Object.values(episode.titles).map(title => ({               
                Title: title.title,
                LanguageId: title.languageId,
                TitleTypeId: title.titleTypeId ? title.titleTypeId : 2
            })),
            Transmissions: Object.values(episode.transmissions).map(transmission => ({
                Id: transmission.id,
                EpisodeId: episode.id,
                ChannelId: transmission.channelId,
                Date: transmission.date
            }))
        })) : []

    }
    return transformed
}


const transformEpisodeDraftToEpisodeInfo = (episodeOriginal, episodeDraft) => {

    //TODO: Need to send only the changed fields; delta

    var presentation = mergeEpisodeChanges(episodeOriginal, episodeDraft)

    const transformed = {
        Id: presentation.id,
        DurationMinutes: presentation.durationMinutes,
        IdaCode: presentation.idaCode,
        YearOfProduction: presentation.yearOfProduction,
        Number: presentation.number,
        Series: presentation.series,
        SapCode: presentation.sapCode,
        Comments: presentation.comments,
        ProgrammeSapCode: presentation.programmeSapCode,
        Titles: Object.values(presentation.titles).map(title => ({            
            Title: title.title,
            LanguageId: title.languageId,
            TitleTypeId: title.titleTypeId? title.titleTypeId : 2
        })),
        Transmissions: Object.values(presentation.transmissions).map(transmission => ({
            Id: transmission.id,
            EpisodeId: presentation.id,
            ChannelId: transmission.channelId,
            Date: transmission.date
        }))

    }
    return transformed
}

const updateDataByEndpoint = async (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformDraftToProgrammeInfo(body, original, episodeOriginal, episodeDraft);

        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {            
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const updateEpisodeDataByEndpoint = async (endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate) => {
    let responseCode = -1

    try {
        const transformedBody = transformEpisodeDraftToEpisodeInfo(episodeOriginal, episodeDraft);

        const response = await authFetch(endpoint, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'AggregateVersion': aggregateVersion
            },
            body: JSON.stringify(transformedBody),
            signal: controller.signal,
        })

        if (controller.signal.aborted) {
            return null
        }
        responseCode = response.status

        if (responseCode === 200) {
            const aggregateVersionResponse = response.headers.get("aggregateversion")
            console.log('AggregateVersion:', aggregateVersionResponse)
            setNewAggregate(aggregateVersionResponse)
            return { responseCode } // Return response code and new aggregate version
        }

        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    } catch (error) {
        throw {
            Message: 'The AV data update was not successful',
            StatusCode: responseCode,
            Details: error,
        }
    }
}

const setUpUpdate = (endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateDataByEndpoint(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate),
    }
}

const setUpEpisodeUpdate = (endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate) => {
    const controller = new AbortController()
    return {
        controller,
        update: () => updateEpisodeDataByEndpoint(endpoint, episodeDraft, episodeOriginal, aggregateVersion, controller, setNewAggregate),
    }
}

// ReSharper disable once InconsistentNaming
const DataUpdate = (props) => {
    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const requestSelector = props.requestSelector
    const onComplete = props.onComplete
    const setAggregate = props.setAggregateAction
    const episodeOnly = props.episodeOnly

    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setNewAggregate = (newAggregate) => dispatch(setAggregate(newAggregate))

    const dataUpdateRequest = useSelector(requestSelector)
    const { endpoint, status, body, original, episodeDraft, episodeOriginal, aggregateVersion } = dataUpdateRequest

    const handleUpdate = async () => {
        if (episodeOnly) {
            setStatus({ updateStatus: 'pending' })
        }
        else {
            setStatus({ status: 'pending' })
        }
        const { controller, update } = episodeOnly
            ? setUpEpisodeUpdate(endpoint, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate)
            : setUpUpdate(endpoint, body, original, episodeDraft, episodeOriginal, aggregateVersion, setNewAggregate);

        setAbortController(controller);

        try {
            const result = await update()
            if (result) {
                if (episodeOnly) {
                    setStatus({ updateStatus: 'complete' });
                }
                else {
                    setStatus({ status: 'complete' });
                }
                if (onComplete) {
                    setTimeout(() => {
                        onComplete()
                    }, 5000);
                }
            }
        } catch (error) {
            setStatus({ status: 'error', error: error })
        }
    }

    if (status === 'idle') {
        handleUpdate()
    }

    return null
}

export default DataUpdate
