import { createSelector } from 'reselect'
import { selectAppSettings } from 'providers/AppSettings/store/selectors'
import { selectComponents } from '../../store'

export const selectHeader = (store) => selectComponents(store).header
export const selectAuthentication = (store) => store.authentication


if (!selectHeader) {
    throw new Error('selectHeader is null')
}
if (!selectAppSettings) {
    throw new Error('selectAppSettings is null')
}

export const selectHeaderAppSettings = (store) => selectAppSettings(store).Header
export const selectAuthenticationAppSettings = (store) => selectHeaderAppSettings(store).Authentication
export const selectSearchModeSettings = (store) => selectHeaderAppSettings(store).searchModes

export const selectAuthenticatedDepartments = createSelector(
        [selectAuthentication],
        authentication => authentication.departments
    )

export const selectSearchModes = createSelector(
        [selectSearchModeSettings],
        searchModeSettings => searchModeSettings
    )

export const selectSearchMode = createSelector(
        [selectHeader, selectSearchModeSettings],
        (header, modes) => modes[header.searchMode]
)

export const selectSearchModeId = createSelector(
    [selectHeader],
    (header) => header.searchMode
)

//export const selectSignOutUrl = createSelector(
//        [selectAuthenticationAppSettings],
//        appSettings => appSettings.SignOutUrl
//    )
