import React, { useRef } from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useDispatch, useSelector } from 'react-redux'
import { selectEpisodeResultPresentation, selectEpisodeResultOriginal, selectProgrammeGuid } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setEpisodeDraftResultsTransmissionsAction, clearEpisodeDraftResultsTransmissionsAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { selectAvApiHost } from 'globalSelectors' 
import { fileDownload } from 'api/FileDownload/fileDownload'

export function Transmissions(props) {
    const dispatch = useDispatch()
    const setAcceptedTransmission = (transmission) => dispatch(setEpisodeDraftResultsTransmissionsAction(transmission))
    const clearTransmission = (transmission) => dispatch(clearEpisodeDraftResultsTransmissionsAction(transmission))
    const toast = React.useRef(null)
    const childRef = useRef()
    const episodeDataOriginal = useSelector(selectEpisodeResultOriginal)
    const episodeData = useSelector(selectEpisodeResultPresentation)
    const programmeId = useSelector(selectProgrammeGuid)
    const { channels } = useSelector(selectAllAssets)
    const avHost = useSelector(selectAvApiHost)
    const allTransmissions = episodeData && episodeData.transmissions ? episodeData.transmissions : []

    const excelDownloadName = 'Transmissions.xlsx'
    const excelDownloadPath = `${avHost}/Programme/${programmeId}/Episodes/${episodeData.id}/TransmissionsExcelExport`

    const transformTransmissions = (transmissions) => {
        const transformedTransmissions = Object.values(transmissions).map((d) => ({
            ...d,
            date: new Date(d.date),
            time: new Date(d.date),
            channel: channels.find(c => c.id === d.channelId)
        }))
        return transformedTransmissions
    }

    const handleAddTransmission = () => {
        if (childRef.current) {
            childRef.current.addNewRow()
        }
    }

    const handleDeleteTransmission = (rowData, rowIndex) => {
        clearTransmission(rowData.id)
        childRef.current.deleteRow(rowIndex)
    }

    const validateData = (rowData) => {
        const errors = {}
        if (!rowData.date || rowData.date === '') errors.date = 'Date is mandatory'
        if (!rowData.time || rowData.time === '') errors.time = 'Time is mandatory'
        if (!rowData.channel || rowData.channel === '') errors.channel = 'Channel is mandatory'
        return errors
    }

    const handleRowEditSave = (rowData) => {
        const { channel, date, time, ...rest } = rowData
        const updatedRowData = {
            ...rest,
            date: new Date(date.setHours(time.getHours(), time.getMinutes())).toISOString().slice(0, 19),
            channelId: channel.id
        }
        const originalData = episodeDataOriginal.transmissions ? episodeDataOriginal.transmissions[rowData.id] : null;
            (originalData && JSON.stringify(originalData) === JSON.stringify(updatedRowData)) ? clearTransmission(rowData.id) : setAcceptedTransmission({ [rowData.id]: updatedRowData })
    }

    const header = (
        <div className="d-flex justify-content-end">
            <Button id={props.id + '_container_transmission_dataTable_export_excel'} className="mr-3" label="Export" icon="fa-light fa-file-import" onClick={() => fileDownload(excelDownloadPath, excelDownloadName)} />
            <Button id={props.id + '_container_transmission_add'} label="Add Transmission" icon="fa-light fa-plus" onClick={handleAddTransmission} />
        </div>
    )
    const displayActions = function (rowData, rowIndex) {
        return (
            <div id={props.id + '_avList_TransmissionGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button className="ml-2" icon="fa-light fa-trash" rounded onClick={() => handleDeleteTransmission(rowData, rowIndex.rowIndex)} />
            </div>
        )
    }

    const transmissionColumnDefinitions = [
        { title: 'Date', mapsToField: 'date', filter: false, filterMatchMode: FilterMatchMode.DATE_IS, sortable: true, editable: true },
        { title: 'Time', mapsToField: 'time', filterMatchMode: FilterMatchMode.EQUALS, filter: false, sortable: false, editable: true },
        { title: 'Channel', mapsToField: 'channel', editable: true },
        { actions: true, template: displayActions }
    ]

    return (
        <div id={props.id + '_transmissions'}>
            <Toast ref={toast} />
            <StaticCommonGrid
                id={props.id + '_transmissions_commonGrid'}
                dataKey="id"
                ref={childRef}
                scrollHeight="180px"
                data={transformTransmissions(allTransmissions)}
                totalCount={transformTransmissions(allTransmissions).length}
                emptyMessage="No Transmissions"
                columnDefinitions={transmissionColumnDefinitions}
                showPaginator={false}
                header={header}
                excelDownloadPath={excelDownloadPath}
                excelDownloadName={excelDownloadName}
                allowRowEdits={true}
                validateData={validateData}
                onSave={handleRowEditSave}
            />
        </div>
    )
}