import { useState, useRef, useEffect } from 'react'
import { useRefAsState } from 'modules/React.Extensions'
import { useSelector, useDispatch } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'

const createDataByEndpoint = async (endpoint, controller, requestBody) => {    
    let responseCode = -1   
    try {
        const response = await authFetch(endpoint, {
            body: requestBody ? JSON.stringify(requestBody) : undefined,
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json'               
            },            
            signal: controller.signal
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }

        responseCode = response.status

        if (responseCode === 201) {
            const json = await response.json()
            return json
        }

        throw {
            Message: 'The AV create call was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    }
    catch (error) {
        throw {
            Message: 'The AV create call was not successful',
            StatusCode: responseCode,
            Details: error
        }
    }
}

const setUpCreate = function (endpoint,requestBody) {
    
    const controller = new AbortController()
    return {
        controller,
        fetch: () => createDataByEndpoint(endpoint, controller, requestBody)
    }
}

// ReSharper disable once InconsistentNaming
const DataCreate = (props) => {

    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const setSapCodeAction = props.setSapCodeAction
    const requestSelector = props.requestSelector
    const requestBodySelector = props.requestBodySelector

    /* Dispatch */
    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setSapCode = (result) => dispatch(setSapCodeAction(result))

    /* Selectors */
    const dataEntryRequest = useSelector(requestSelector)
    const requestBody = useSelector(requestBodySelector)

    //Only perform fetch once when the component renders for the first time.
    //TODO: fix the infinite render loop that is occurring if useEffect is removed
    useEffect(()=> {
            
        const { endpoint/*, status*/ } = dataEntryRequest
    
        if (/*status === 'pending' &&*/ abortController) {
            abortController.abort()
        }

        setStatus({ status: 'pending' })

        var { controller, fetch } = setUpCreate(endpoint,requestBody)
        setAbortController(controller)
            fetch()
                .then(result => {
                    if (result) {
                        setSapCode(result)
                        setStatus({ status: 'complete' })
                    }
                    //else was aborted
                })
                .catch(error => {
                    setSapCode({})
                    setStatus({ status: 'error', error: error })
                }).finally(()=>
                {
                    
                if (props.onComplete)
                    {
                        props.onComplete();
                    }
                }
            )
    },[]);
    return undefined
}
export default DataCreate