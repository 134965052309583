// Prelim Imports - some of these might be valid imports, some might just
// be early demo code. TODO - sort through this list.

import Home from 'components/Body/Home';

// Definite, SCRIBE imports

import { FullSearch } from 'components/Body/Search'
import { AudioVisualDetails, PublishedWorkDetails, SerialDetails, VisualArtDetails, ContactDetails, ArticleDetails, WebsiteDetails, DistributionDetails, ImportDetails, SourceDetails } from 'components/Body/data-entry'
import { AudioVisualLanding } from 'components/Body/Landing' 
import LogOutButton from 'components/Header/LogOutButton'

const commonDetailsClassName = 'col-6'
const commonSearchResultsClassName = 'col-6'


/**************************************************************************************
 * Note that the hardwired paths in this object must tally with those in
 * Navigation.js. DO NOT CHANGE.
 **************************************************************************************/
// ReSharper disable once InconsistentNaming
const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    /* SCRIBE Routes */
    {
        path: '/search',
        element: <FullSearch id="FullSearch" className={commonSearchResultsClassName } />
    }, 
    {
        path: '/AudioVisual',
        element: <AudioVisualLanding id="AudioVisualLanding" className={commonDetailsClassName} />
    },
    {
        path: '/AudioVisual/:id?',
        element: <AudioVisualDetails id="AudioVisualDetails" className={commonDetailsClassName} />
    },
    {
        path: '/AudioVisual/:id?/:episodeId?',
        element: <AudioVisualDetails id="AudioVisualDetails" className={commonDetailsClassName} />
    },
    {
        path: '/PublishedWork/:id?',
        element: <PublishedWorkDetails id="PublishedWorkDetails" className={commonDetailsClassName} />
    },
    {
        path: '/serial/:id?',
        element: <SerialDetails id="SerialDetails" className={commonDetailsClassName} />
    },
    {
        path: '/VisualArt/:id?',
        element: <VisualArtDetails id="VisualArtDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Contact/:id?',
        element: <ContactDetails id="ContactDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Article/:id?',
        element: <ArticleDetails id="ArticleDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Website/:id?',
        element: <WebsiteDetails id="WebsiteDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Distribution/:id?',
        element: <DistributionDetails id="DistributionDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Import/:id?',
        element: <ImportDetails id="ImportDetails" className={commonDetailsClassName} />
    },
    {
        path: '/Source/:id?',
        element: <SourceDetails />
    },
    {
        path: '/LogOut',
        element: <LogOutButton id = "LogOut" />
    }


]

export default AppRoutes;
