import { selectBodyAppSettings, selectBody } from '../../../store/selectors'
import { selectAvApiHost } from 'globalSelectors'
import { createSelector } from 'reselect'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { testProgrammeObject } from '../testProgrammeObject'
import { v4 as uuidv4 } from 'uuid';

export const selectBodyDatEntryApp = (store) => selectBody(store).dataEntry
export const selectBodyDataEntryProgramme = (store) => selectBodyDatEntryApp(store).audioVisual


const fetchRequest = (store, programmeId, url) => ({ endpoint: `${url}/${programmeId}`, status: store.status, method:'GET' })
const episodeFetchRequest = (store, programmeId, episodeId, url) => ({ endpoint: `${url}/${programmeId}/Episodes/${episodeId}`, status: store.status })
const createRequest = (store, programmeId, url) => ({ endpoint: `${url}/${programmeId}`, status: store.status, method: 'PUT' })
const programmeUpdateRequest = (store, programmeId, url, draft, original, episodeDraft, episodeOriginal, aggregateVersion) => ({ endpoint: `${url}/${programmeId}`, status: store.updateStatus, body: draft, original: original, episodeDraft: episodeDraft, episodeOriginal: episodeOriginal, aggregateVersion: aggregateVersion })
const episodeUpdateRequest = (store, programmeId, episodeId, status, url, episodeDraft, episodeOriginal, aggregateVersion) => ({ endpoint: `${url}/${programmeId}/Episodes/${episodeId}`, status: status, episodeDraft: episodeDraft, episodeOriginal: episodeOriginal, aggregateVersion: aggregateVersion })

const mergeChanges = (original, draft) => {
    if (original) {       

        // Merge titles
        const mergedTitles = draft?.titles || original?.titles || {}
          
        const mergedCountries = { 
            ...original.countries,
            ...draft?.countries || []
        }

        const mergedEpisodes = {
            ...original.episodes,
            ...draft?.episodes || []
        }

        //// Filter out titles with delete tag set to true
        //const filteredTitles = Object.entries(mergedTitles)
        //    .filter(([key, title]) => !title.delete)
        //    .reduce((obj, [key, title]) => {
        //        obj[key] = title
        //        return obj
        //    }, {})

        const filteredTitlesArray = Object.values(mergedTitles)

        const filteredCountries = Object.entries(mergedCountries)
            .filter(([key, country]) => !country.delete)
            .reduce((obj, [key, country]) => {
                obj[key] = country
                return obj
            }, {})

        const filteredEpisodes = Object.entries(mergedEpisodes)
        .filter(([key, episode]) => !episode.delete)
            .reduce((obj, [key, episode]) => {
                obj[key] = episode
                return obj
            }, {})

        return {
            ...original,
            ...draft,
            titles: filteredTitlesArray,
            countries: filteredCountries,
            episodes: filteredEpisodes
        }
    }
    return {}
}

const mergeEpisodeChanges = (original, draft) => {

    if (original) {
      
        // Merge titles
            const mergedTitles = draft?.titles || original?.titles || {}       
        
        const filteredTitlesArray = Object.values(mergedTitles)

        const mergedTransmissions = {
            ...original.transmissions,
            ...draft?.transmissions || []
        }

        // Filter out transmissions with delete tag set to true
        const filteredTransmissions = Object.entries(mergedTransmissions)
            .filter(([key,transmission]) => !transmission.delete)
            .reduce((obj, [key, transmission]) => {
                obj[key] = transmission
                return obj
            }, {})

        return {
            ...original,
            ...draft,
            titles: filteredTitlesArray,
            transmissions: filteredTransmissions
        }
    }
    return {}
}

/*AudioVisual */

export const selectProgrammeUrl = createSelector(
    [selectAvApiHost],
    host => `${host}/Programme`
)

export const selectProgrammeGuid = createSelector(
    [selectBodyDataEntryProgramme],
    (av) => av.guid
)

//TODO: Test selector. When creating a programme I believe a new GUID needs to be generated and added to the store
export const selectFakeProgrammeGuid = createSelector(
    [],
    () => uuidv4()
)


export const selectUpdateStatus = createSelector(
    [selectBodyDataEntryProgramme],
    (av) => av.updateStatus
)

export const selectUpdateErrorDetailMessage = createSelector(
    [selectUpdateStatus],
    (status) => status.error ? status.error.Message : null
)

export const selectAudioVisualResultOriginal = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, guid) => av[guid]?.original
)

export const selectAudioVisualResultOriginalAggregateVersion = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, guid) => av[guid]?.original.version
)

export const selectAudioVisualResultDraft = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, guid) => av[guid]?.draft
)

export const selectAudioVisualResultPresentation = createSelector(
    [selectAudioVisualResultOriginal, selectAudioVisualResultDraft],
    mergeChanges
)
export const selectAudioVisualResultPresentationTitles = createSelector(
    [selectAudioVisualResultPresentation],
    (presentation) => presentation?.titles
)

export const selectProgrammeFetchRequest = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid, selectProgrammeUrl],
    fetchRequest
)
export const selectProgrammeCreateRequest = createSelector(
    [selectBodyDataEntryProgramme, selectFakeProgrammeGuid, selectProgrammeUrl],
    createRequest
)

//TODO: need to replace this with a selector that pulls from the actual programme data in the store
//Need to coerce this data into the format accepted by the API
export const selectProgrammeCreateBody = createSelector(
    [],
     testProgrammeObject
)

export const selectEpisodeResultOriginal = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, programmeId) => av[programmeId]?.episode?.original
)

export const selectEpisodeResultDraft = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, programmeId) => av[programmeId]?.episode?.draft
)

export const selectEpisodeResultStatus = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, programmeId) => av[programmeId]?.episode?.status
)
export const selectEpisodeResultUpdateStatus = createSelector(
    [selectEpisodeResultStatus],
    (status) => status?.updateStatus  
    
)

export const selectProgrammeUpdateRequest = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid, selectProgrammeUrl, selectAudioVisualResultDraft, selectAudioVisualResultOriginal, selectEpisodeResultDraft, selectEpisodeResultOriginal , selectAudioVisualResultOriginalAggregateVersion],
    programmeUpdateRequest
)

export const selectProgrammeFormats = createSelector(
    [selectAllAssets],
    (assets) => ({ programmeTypes: assets.programmeTypes, programmeFormatTypes: assets.programmeFormatTypes })
)

export const selectEpisodeGuid = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid],
    (av, programmeId) => av[programmeId]?.episode?.guid ?? null
)



export const selectEpisodeResultPresentation = createSelector(
    [selectEpisodeResultOriginal, selectEpisodeResultDraft],
    mergeEpisodeChanges
)
export const selectEpisodetPresentationEpisodeTitles = createSelector(
    [selectEpisodeResultPresentation],
    (presentation) => presentation?.titles
)
export const selectEpisodeFetchRequest = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid, selectEpisodeGuid, selectProgrammeUrl],
    episodeFetchRequest
)

export const selectAudioVisualResultHasChanges = createSelector(
    [selectAudioVisualResultDraft, selectEpisodeResultDraft],
    (draft, episodeDraft) => (draft != null && Object.keys(draft).length > 0) || (episodeDraft != null && Object.keys(episodeDraft).length > 0)
)

export const selectEpisodeResultHasChanges = createSelector(
    [selectEpisodeResultDraft],
    (episodeDraft) => (episodeDraft != null && Object.keys(episodeDraft).length > 0)
)

export const selectEpisodeDraftHasDeletedTransmissions = createSelector(
    [selectEpisodeResultDraft],
    (episodeDraft) => {
        if (!episodeDraft || !episodeDraft.transmissions) {
            return false;
        }
        return Object.values(episodeDraft.transmissions).some(transmission => transmission.delete);
    }
)

export const selectEpisodeUpdateRequest = createSelector(
    [selectBodyDataEntryProgramme, selectProgrammeGuid, selectEpisodeGuid, selectEpisodeResultUpdateStatus, selectProgrammeUrl, selectEpisodeResultDraft, selectEpisodeResultOriginal, selectAudioVisualResultOriginalAggregateVersion],
    episodeUpdateRequest
)

//Episode Update Status

export const selectEpisodeUpdateErrorDetailMessage = createSelector(
    [selectEpisodeResultUpdateStatus],
    (updateStatus) => updateStatus?.error ? updateStatus.error.Message : null
)




