import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { FilterMatchMode } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { selectAllAssets } from 'providers/AssetLoader/store/selectors'
import { AutoComplete } from 'primereact/autocomplete'
import { useSelector } from 'react-redux'
import { DomHandler } from 'primereact/utils';
import { Toast } from 'primereact/toast'

// ReSharper disable once InconsistentNaming
const StaticCommonGrid = forwardRef((props, ref) => {
    const {
        dataKey,
        totalCount,
        emptyMessage,
        columnDefinitions,
        showPaginator,
        header,
        selectionMode,
        selectionStyle,
        scrollHeight,
        rowPerPage,
        allowRowEdits
    } = props

    const [validationErrors, setValidationErrors] = useState({});
    const [multiSortMeta, setMultiSortMeta] = useState([])
    const [filters, setFilters] = useState(null);
    const [filteredChannels, setFilteredChannels] = useState([])
    const [localData, setLocalData] = useState([...props.data])
    const [selectedChannels, setSelectedChannels] = useState(() => {
        const initialSelectedChannels = {}
        localData.forEach((rowData) => {
            initialSelectedChannels[rowData[dataKey]] = rowData.channel?.name || null
        });
        return initialSelectedChannels
    })

    const { channels } = useSelector(selectAllAssets)

    const paginatorPosition = 'bottom'
    const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
    const currentPageReportTemplate = 'Showing results {first}-{last} of {totalRecords}'
    const defaultRowsPerPage = 50
    const rowsPerPageOptions = [10, 25, defaultRowsPerPage, 100, 500]

    useImperativeHandle(ref, () => ({
        addNewRow: () => {
            openNewRowInEditMode()
        },
        deleteRow: (rowIndex) => {
            localData.splice(rowIndex, 1)
            setLocalData([...localData])
        }
    }));

    const openNewRowInEditMode = () => {
        const newRow = {}
        newRow[dataKey] = crypto.randomUUID()
        columnDefinitions.forEach((col) => {
            if (col.mapsToField !== dataKey) {
                newRow[col.mapsToField] = null
            }
        })
        setLocalData((prevLocalData) => ([newRow, ...prevLocalData]))
        localData.unshift(newRow)

        requestAnimationFrame(() => {
            DomHandler.find(document.body, '.p-row-editor-init')[0].click()
        }, 0)
    }

    const rowEditValidator = (rowData) => {
        const errors = props.validateData(rowData)
        if (Object.keys(errors).length > 0) {
            const rowIndex = localData.findIndex(row => row[dataKey] === rowData[dataKey])
            setValidationErrors({ [rowIndex]: errors })
            Toast.current.show({ severity: 'error', summary: 'Error', detail: Object.values(errors).join(', ') })
            return false
        }
        else {
            props.onSave(rowData)
            setValidationErrors({})
            console.log('Validation passed')
            const updatedData = [...localData]
            const rowIndex = updatedData.findIndex(row => row[dataKey] === rowData[dataKey])
            if (rowIndex !== -1) {
                updatedData[rowIndex] = rowData
                setLocalData(updatedData)
            }
            return true
        }
    }

    const onRowEditCancel = (e) => {
        const rowIndex = localData.findIndex(row => row[dataKey] === e.data[dataKey])
        const existingItem = props.data.find(row => row[dataKey] === e.data[dataKey])
        setValidationErrors({})

        if (!existingItem) {
            // Delete the item from localData
            if (rowIndex !== -1) {
                localData.splice(rowIndex, 1)
                setLocalData([...localData])
            }
        } else {
            const newDataKey = existingItem[dataKey]
            const selectedChannel = props.data.find(row => row[dataKey] === newDataKey)?.channel?.name || ''
            const newSelectedChannels = { ...selectedChannels, [newDataKey]: selectedChannel }
            setSelectedChannels(newSelectedChannels)
        }
    }

    const numericBodyTemplate = (rowData, field) => {
        return (
            <span>{rowData[field]}</span>
        )
    }

    const numericFilterTemplate = (options, field) => {
        const filterOptions = options[field + 'Options'];
        return (
            <InputNumber
                value={options.value}
                options={filterOptions}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Search by ${field}`}
            />
        )
    }

    const dateBodyTemplate = (rowData) => {
        return rowData.date?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) || ''
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" />
    }
    const timeBodyTemplate = (rowData) => {
        return rowData.time?.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) || ''
    }

    const timeFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                showTime={true}
                timeOnly
                hourFormat="24"
            />
        )
    }

    const inputTextEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const search = (event) => {
        // Timeout to emulate a network connection
        setTimeout(() => {
            let filteredChannels1;

            if (!event.query.trim().length) {
                filteredChannels1 = [...channels];
            }
            else {
                filteredChannels1 = channels.filter((channel) => {
                    return channel.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredChannels(filteredChannels1);
        }, 250);
    }

    const channelsAutoCompleteEditor = (options) => {
        if (options.field === 'channel') {
            const rowData = options.rowData
            const rowIndex = options.rowIndex
            const newDataKey = rowData[dataKey]
            const selectedChannel = selectedChannels[newDataKey] || ''
            return (
                <AutoComplete
                    value={selectedChannel}
                    suggestions={filteredChannels}
                    completeMethod={search}
                    onChange={(e) => {
                        const newSelectedChannels = { ...selectedChannels }
                        newSelectedChannels[newDataKey] = e.value
                        setSelectedChannels(newSelectedChannels)
                        options.editorCallback(e.value)
                    }}
                    className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
                    field="name"
                />
            );
        }
        return undefined
    };

    const calendarEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                showTime={false}
                dateFormat="dd/mm/yy"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const timeEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                showTime={true}
                timeOnly={true}
                hourFormat="24"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
            />
        )
    }

    const numericEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <InputNumber
                value={options.value}
                onValueChange={(e) => options.editorCallback(e.target.value)}
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''}
                thousandSeparator={false}
            />
        )
    }

    const yearBodyTemplate = (rowData, field) => {
        const value = rowData[field]
        return value instanceof Date ? value.getFullYear() : new Date(value, 0, 1).getFullYear()
    }

    const yearEditor = (options) => {
        const rowIndex = options.rowIndex
        return (
            <Calendar
                value={options.value ? new Date(options.value, 0, 1) : null}
                onChange={(e) => options.editorCallback(e.target.value instanceof Date ? e.target.value.getFullYear() : e.target.value)}
                view="year"
                dateFormat="yy"
                className={validationErrors[rowIndex] && validationErrors[rowIndex][options.field] ? 'p-invalid' : ''} />
        )
    }

    const createColumn = function (title, mapsToField, filter, actions, template, sortable, filterMatchMode, editable) {
        const filterAttributes = {
            filter,
            filterPlaceholder: `Search by  ${title}`
        }
        const sortAttributes = {
            sortable
        }
        if (sortable) {
            sortAttributes.required = true
            sortAttributes.disabled = false
        }
        else {
            sortAttributes.required = false
            sortAttributes.disabled = true
        }
        if (filter) {
            filterAttributes.required = true
            filterAttributes.disabled = false
        }
        else {
            filterAttributes.required = false
            filterAttributes.disabled = true
        }
        if (filterMatchMode === FilterMatchMode.EQUALS) {
            if (mapsToField === 'time') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => timeBodyTemplate(rowData, mapsToField)}
                        editor={editable ? (options) => timeEditor(options) : null}
                        filterElement={(options) => timeFilterTemplate(options)}
                        {...filterAttributes}
                    />
                )
            } else if (mapsToField === 'yearOfProduction') {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={(rowData) => yearBodyTemplate(rowData, mapsToField)}
                        editor={editable ? (options) => yearEditor(options) : null}
                        filterElement={(options) => numericFilterTemplate(options, mapsToField)}
                        {...filterAttributes}
                    />
                )
            } else {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={template}//{(rowData) => numericBodyTemplate(rowData, mapsToField)} //TODO-numericBodyTemplate simply returns the field.
                        editor={editable ? (options) => numericEditor(options) : null}
                        filterElement={(options) => numericFilterTemplate(options, mapsToField)}
                        {...filterAttributes}
                    />
                )
            }
        }

        if (filterMatchMode === FilterMatchMode.DATE_IS) {
            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    dataType="date"
                    body={(rowData) => dateBodyTemplate(rowData, mapsToField)}
                    editor={editable ? (options) => calendarEditor(options) : null}
                    filterElement={(options) => dateFilterTemplate(options)}
                    {...filterAttributes}
                />
            )
        }

        if (mapsToField === 'channel') {
            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    body={(rowData) => rowData.channel?.name}
                    editor={editable ? (options) => channelsAutoCompleteEditor(options) : null}
                    {...filterAttributes}
                />
            )
        }

        if (actions) {
            return (
                <Column
                    body={template}
                    style={{ textAlign: 'left', borderLeft: 'none' }}
                    colSpan={2}
                />
            )
        }

        return (
            <Column
                {...sortAttributes}
                key={mapsToField}
                field={mapsToField}
                header={title}
                body={template}
                editor={editable ? (options) => inputTextEditor(options) : null}
                {...filterAttributes}
            />
        )
    }

    return (
        <div id={props.id + '_container'}>
            <Toast ref={Toast} />
            <DataTable
                id={props.id + '_container_dataTable'}
                value={localData}
                size="small"
                emptyMessage={emptyMessage}
                selectionMode={selectionStyle === "checkbox" ? "checkbox" : "single"}
                scrollable
                scrollHeight={scrollHeight ? scrollHeight : (showPaginator ? "250px" : "290px")}
                showGridlines
                stripedRows
                removableSort
                filters={filters}
                paginator={showPaginator}
                paginatorPosition={paginatorPosition}
                paginatorTemplate={paginatorTemplate}
                currentPageReportTemplate={currentPageReportTemplate}
                rows={rowPerPage || defaultRowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                className="datatable-responsive"
                dataKey={dataKey}
                multiSortMeta={multiSortMeta}
                sortMode="multiple"
                onSort={(e) => setMultiSortMeta(e.multiSortMeta)}
                totalRecords={totalCount}
                header={header}
                onFilter={(e) => setFilters(e.filters)}
                editMode="row"
                rowEditValidator={rowEditValidator}
                onRowEditCancel={onRowEditCancel}
            >
                {selectionMode === "multiple" && <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>}
                {columnDefinitions.filter(col => !col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.actions, col.template, col.sortable, col.filterMatchMode, col.editable)
                )}
                {allowRowEdits && <Column header="Actions" rowEditor={true} bodyStyle={{ display: 'flex', justifyContent: 'flex-end', borderRight: 'none', height: '100%' }} >
                </Column>}
                {columnDefinitions.filter(col => col.actions).map((col) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.actions, col.template, col.sortable, col.filterMatchMode))}
            </DataTable>
        </div>
    )
})

export default StaticCommonGrid