import { useState, useRef } from 'react'
import { useRefAsState } from 'modules/React.Extensions'
import { useSelector, useDispatch } from 'react-redux'
import { authFetch } from 'api/auth/authFetch'

const fetchDataByEndpoint = async (endpoint, controller) => {    
    let responseCode = -1   
    try {
        const response = await authFetch(endpoint, {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json'               
            },            
            signal: controller.signal
        })

        const wasAborted = controller?.signal?.aborted

        controller = null

        if (wasAborted) {
            return null
        }

        responseCode = response.status

        if (responseCode === 200) {
            const json = await response.json()
            return json
        }

        throw {
            Message: 'The AV data fetch was not successful',
            StatusCode: responseCode,
            Details: await response.text()
        }
    }
    catch (error) {
        throw {
            Message: 'The AV data fetch was not successful',
            StatusCode: responseCode,
            Details: error
        }
    }
}

const setUpFetch = function (endpoint) {
    
    const controller = new AbortController()
    return {
        controller,
        fetch: () => fetchDataByEndpoint(endpoint, controller)
    }
}

// ReSharper disable once InconsistentNaming
const DataEntry = (props) => {
  
    const [abortController, setAbortController] = useRefAsState(null)

    const setStatusAction = props.setStatusAction
    const setOriginalResultAction = props.setOriginalResultAction
    const requestSelector = props.requestSelector    

    /* Dispatch */
    const dispatch = useDispatch()
    const setStatus = (newStatus) => dispatch(setStatusAction(newStatus))
    const setOriginalResult = (result) => dispatch(setOriginalResultAction(result))

    /* Selectors */
    const dataEntryRequest = useSelector(requestSelector)

    const { endpoint, status } = dataEntryRequest
   
    if (status === 'pending' && abortController) {
        abortController.abort()        
    }

    setStatus({ status: 'pending' })

    var { controller, fetch } = setUpFetch(endpoint)

    setAbortController(controller)
    fetch()
        .then(result => {
            if (result) {
                setOriginalResult(result)
                setStatus({ status: 'complete' })                
            }
            //else was aborted
        })
        .catch(error => {
            setOriginalResult({})
            setStatus({ status: 'error', error: error })
        })

    return undefined
}
export default DataEntry