export const setProgrammeFetchStatusAction = (status) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_FETCH_STATUS_ACTION', payload: status }
}
export const setProgrammeCreateStatusAction = (status) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_CREATE_STATUS_ACTION', payload: status }
}

export const setProgrammeFetchResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_FETCH_RESULT_ACTION', payload: result }
}

export const setProgrammeOriginalAggregateVersionAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_ORIGINAL_VERSION_ACTION', payload: result }    
}

export const setProgrammeDraftResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ACTION', payload: result }
}

export const setProgrammeDraftResultsTitlesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_TITLES_ACTION', payload: result }
}

export const setProgrammeDraftResultsMasterIdaAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_MASTERIDA_ACTION', payload: result }
}

export const setProgrammeDraftResultsAnimeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ANIME_ACTION', payload: result }
}

export const setProgrammeDraftResultsIsRadioAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_ISRADIO_ACTION', payload: result }
}

export const setProgrammeDraftResultsLanguageAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_LANGUAGE_ACTION', payload: result }
}

export const setProgrammeDraftResultsProgrammeTypeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PROGRAMMETYPE_ACTION', payload: result }
}

export const setProgrammeDraftResultsFormatTypeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_FORMATTYPE_ACTION', payload: result }
}

export const setProgrammeDraftResultsSapCodeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_SAPCODE_ACTION', payload: result }
}

export const setProgrammeDraftResultsProductionCompaniesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_PRODUCTIONCOMPANIES_ACTION', payload: result }
}

export const setProgrammeDraftResultsCountryAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_COUNTRY_ACTION', payload: result }
}

export const setProgrammeDraftResultsEpisodesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_DRAFT_RESULT_EPISODES_ACTION', payload: result }
}

export const clearProgrammeDraftResultsEpisodesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_EPISODES_ACTION', payload: result }
}

export const clearProgrammeDraftResultsCountryAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_COUNTRY_ACTION', payload: result }
}

export const clearProgrammeDraftResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ACTION', payload: result }
}

export const clearProgrammeDraftResultsProgrammeTypeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PROGRAMMETYPE_ACTION', payload: result }
}

export const clearProgrammeDraftResultsFormatTypeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_FORMATTYPE_ACTION', payload: result }
}

export const clearProgrammeDraftResultsIsRadioAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ISRADIO_ACTION', payload: result }
}

export const clearProgrammeDraftResultsMasterIdaAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_MASTERIDA_ACTION', payload: result }
}

export const clearProgrammeDraftResultsAnimeAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_ANIME_ACTION', payload: result }
}

export const clearProgrammeDraftResultsLanguageAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_LANGUAGE_ACTION', payload: result }
}

export const clearProgrammeDraftResultsProductionCompaniesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_DRAFT_PRODUCTIONCOMPANIES_ACTION', payload: result }
}

export const setProgrammeGuidAction = (guid) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_GUID', payload: guid }
}

export const setEpisodeFetchStatusAction = (status) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_STATUS_ACTION', payload: status }
}

export const setEpisodeFetchResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODEFETCH_RESULT_ACTION', payload: result }
}

export const setEpisodeGuidAction = (guid) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODEGUID', payload: guid }
}

export const setEpisodeDraftResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_ACTION', payload: result }
}

export const setEpisodeDraftResultsTransmissionsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_TRANSMISSIONS_ACTION', payload: result }
}

export const setEpisodeDraftResultsTitleAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_RESULT_TITLE_ACTION', payload: result }
}

export const clearEpisodeDraftResultsTransmissionsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_TRANSMISSIONS_ACTION', payload: result }
}

export const clearEpisodeResultsTransmissionsFromDraftsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_TRANSMISSION_ACTION', payload: result }
}

export const clearEpisodeResultsTitleFromDraftsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_DELETE_EPISODE_DRAFT_RESULT_TITLE_ACTION', payload: result }
}



//Programme Update

export const setProgrammeUpdateStatusAction = (status) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_PROGRAMMEUPDATE_STATUS_ACTION', payload: status }
}

export const setProgrammeUpdateResultsAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_PROGRAMMEUPDATE_RESULT_ACTION', payload: result }
}

//Episode Duration

export const setEpisodeDraftResultDurationAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_DURATION_ACTION', payload: result }
}

export const clearEpisodeDraftResultsDurationAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_DURATION_ACTION', payload: result }
}

//Episode Year Of Production

export const setEpisodeDraftResultYearOfProductionAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_YEAR_ACTION', payload: result }
}

export const clearEpisodeDraftResultsYearOfProductionAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_YEAR_ACTION', payload: result }
}

//Episode ida

export const setEpisodeDraftResultIdaAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_IDA_ACTION', payload: result }
}

export const clearEpisodeDraftResultsIdaAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_IDA_ACTION', payload: result }
}

//Episode Update Status

export const setEpisodeUpdateStatusAction = (status) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODEUPDATE_STATUS_ACTION', payload: status }
}

//Episode Series

export const setEpisodeDraftResultSeriesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_SERIES_ACTION', payload: result }
}

export const clearEpisodeDraftResultsSeriesAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_SERIES_ACTION', payload: result }
}

//Episode Number

export const setEpisodeDraftResultNumberAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_SET_EPISODE_DRAFT_NUMBER_ACTION', payload: result }
}

export const clearEpisodeDraftResultsNumberAction = (result) => {
    return { type: 'DATAENTRY_AUDIOVISUAL_EPISODE_DELETE_DRAFT_NUMBER_ACTION', payload: result }
}

