import { combineReducers } from '@reduxjs/toolkit';

const initialState = {
    breadCrumbStack: {
        items: []
    }
}

const breadCrumbItemReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SetBreadCrumbStack':
            return {
                ...state,
                breadCrumbStack: {
                    items: [...action.payload]
                }
            }
        default:
            return state
    }
}

export const secondaryNavigationReducer = combineReducers({
    breadCrumb: breadCrumbItemReducer    
})