import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import edit from 'assets/images/edit.svg'
import FullTextSearch from 'api/FullTextSearch'
import { Button } from 'primereact/button'
import { Badge } from 'primereact/badge'
import { InputNumber } from 'primereact/inputnumber'
import { FilterMatchMode } from 'primereact/api'
import { fileDownload } from 'api/FileDownload/fileDownload'


// ReSharper disable once InconsistentNaming
const CommonGrid = (props) => {
    // Deconstruct props to get specific component related selectors and actions
    const {
        dataKey,
        totalCount,
        emptyMessage,
        columnDefinitions,
        selectTotalPages,
        selectFirst,
        selectRows,
        fetchStatusSelector,
        setTotalPagesAction,
        setCurrentPageAction,
        setFirstAction,
        setRowsAction,
        setFilterAction,
        setSortAction,
        fetchResultsAction,
        fetchStatusAction,
        requestSelector,
        scrollHeight
    } = props
    const [expandedRows, setExpandedRows] = useState(null)
    const [multiSortMeta, setMultiSortMeta] = useState([])
    const [filters, setFilters] = useState(null);
    const [lazyState, setlazyState] = useState({
        filters: {
            Id: { value: null, matchMode: FilterMatchMode.EQUALS},
            series: { value: null, matchMode: FilterMatchMode.EQUALS },
            year: { value: null, matchMode: FilterMatchMode.EQUALS },
            ida: { value: null, matchMode: FilterMatchMode.EQUALS },
        }
    })
    
    const defaultRowsPerPage = 100

    // Use specific domain related selectors and actions
    const first = useSelector(selectFirst)
    const rows = useSelector(selectRows)
    const fetchStatusSelect = useSelector(fetchStatusSelector)
    const fetchStatus = fetchStatusSelect['status']
    const dispatch = useDispatch()

    // Calculate total pages
    const pagesCount = totalCount > 0 ? Math.ceil(totalCount / 10) : 0
    const selectPageCount = useSelector(selectTotalPages)

    // Dispatch action to set total pages if it's different
    if (selectPageCount !== pagesCount) {
        dispatch(setTotalPagesAction(pagesCount))
    }

    useEffect(() => {
        const lazyLoadEventData = { rows, first, multiSortMeta, filters }
        lazyLoadData(lazyLoadEventData)
    }, [])    

    // Lazy load data function
    const lazyLoadData = (event) => {
        setlazyState(event);
        const currentPage = Math.floor(event.first / event.rows) + 1
        const sortFields = event.multiSortMeta
            .map(sort => `${sort.field} ${sort.order === 1 ? 'asc' : 'desc'}`)
            .join(',')

        setFilters(event.filters)

        const filter = event.filters ? Object.entries(event.filters)
            .flatMap(([field, fieldFilter]) => {
                return fieldFilter.constraints.map(constraint => {
                    if (constraint.value !== null) {
                        return `${field} ${constraint.matchMode} ${constraint.value}`;
                    }
                    return null
                });
            })
            .filter(condition => condition !== null)
            .join(' and ') : null

        setMultiSortMeta(event.multiSortMeta)
        dispatch(setCurrentPageAction(currentPage))
        dispatch(setFirstAction(event.first))
        dispatch(setRowsAction(event.rows))
        dispatch(setSortAction(sortFields)) 
        dispatch(setFilterAction(filter)) 

    }

    // ALCS-specific constants - although some of these constants might use PrimeReact-specific values

    const csvDownloadPath = props.csvDownloadPath
    const csvDownloadName = props.csvDownloadName
    const excelDownloadPath = props.excelDownloadPath
    const excelDownloadName = props.excelDownloadName

    // ReSharper disable once InconsistentNaming
    
    const header = (
        <div>
            <div className="row d-flex justify-content-flexend">
                <div className="col mt-1">
                    <Badge value={`${first + 1}-${Math.min(first + rows, totalCount)}`}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>of</b> </span>
                    <Badge value={totalCount}> </Badge>
                    <span class="mr-2" style={{ fontSize: "14px", fontWeight: "500", color: "black" }}> <b>Results</b> </span>
                </div>                              

                <div className="col-auto">
                    <Button
                        id={props.id + '_container_dataTable_export_csv'}
                        className="mr-3 ml-2"
                        icon="fa-light fa-file-import"
                        label="CSV Export"
                        onClick={() => fileDownload(csvDownloadPath, csvDownloadName)}
                    >
                    </Button>
                    <Button
                        id={props.id + '_container_dataTable_export_excel'}
                        label="Excel Export"
                        icon="fa-light fa-file-import"
                        onClick={() => fileDownload(excelDownloadPath, excelDownloadName)}
                    >
                    </Button>
                </div>
            </div>
        </div>
    );
    const paginatorPosition = 'bottom'
    const paginatorTemplate =
        /*' RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink JumpToPageInput'*/
        'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'

    const rowsPerPageOptions = [10, 25, 50, defaultRowsPerPage ]

    // Should not need to modify anything after this point
    const allowExpansion = (rowData) => {
        return rowData.orders.length > 0
    }

    const displayActionsTemplate = function (rowData) {
        return (
            <div>
                <img src={edit} width="16" alt="" />
            </div>
        )
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div id={props.id + '_container_dataTable_nestedTable'} className="p-3">
                <DataTable value={data.contributions} scrollable scrollHeight={`${scrollHeight ?? 430}px`}>                
                    <Column field="name" header="Contributor Name"></Column>
                    <Column field="authorKey" header="Author Key"></Column>
                    <Column field="contributionType" header="Contribution"></Column>
                    <Column field="percentage" header="Percent (%)"></Column>
                    <Column field="minutes" header="Minutes Worked"></Column>
                    <Column field="ipiNumber" header="IPI No"></Column>
                    <Column field="noneyet" header="Member Type"></Column>
                </DataTable>
            </div>
        )
    }
    const onRowExpand = (event) => {

    }

    const onRowCollapse = (event) => {

    } 

    const numericBodyTemplate = (rowData, field) => {
        return (
            <span>{rowData[field]}</span>
        );
    };

    const numericFilterTemplate = (options, field) => {
        const filterOptions = options[field + 'Options'];
        return (
            <InputNumber
                value={options.value}
                options={filterOptions}
                onChange={(e) => options.filterCallback(e.value, options.index)}
                placeholder={`Search by ${field}`}
            />
        );
    };

    const createColumn = function (title, mapsToField, filter, expand, template, sortable, filterMatchMode) {
        // This is horrible code, but any appearance of the word "filter" in the
        // (PR) element below causes PR to draw a filter (even filter=false) so the code has to be divergent.
        // So. just because logic exists, we hive this to a function of its own.
        // Note that the same will probably be true of the sortable property, although currently everything is sortable.

        // NB - to have the grid just display text in the cell, we just *don't pass* the "template"
        // variable. Here, that'll set it to "undefined", which tricks the grid into falling back to its
        // default rendering mechanism (just to write out the contents of the cell).

        const filterAttributes = {
            filter,
            filterPlaceholder: `Search by  ${title}`
        }

        const expandAttributes = {
            expander: { allowExpansion }
        }
        const sortAttributes = {
            sortable
        }
        if (sortable) {
            sortAttributes.required = true
            sortAttributes.disabled = false
        }
        else {
            sortAttributes.required = false
            sortAttributes.disabled = true
        }
        if (filter) {
            filterAttributes.required = true
            filterAttributes.disabled = false
        }
        else {
            filterAttributes.required = false
            filterAttributes.disabled = true
        }
        if (expand) {
            expandAttributes.required = true
            expandAttributes.disabled = false
        }
        else {
            expandAttributes.required = false
            expandAttributes.disabled = true
        }
        if (expand) {
            return (
                <Column
                    style={{ width: '8rem' }}
                    {...expandAttributes}
                    header={title}
                >
                </Column>
            )
        }
        else {
            if (filterMatchMode === FilterMatchMode.EQUALS) {
                return (
                    <Column
                        {...sortAttributes}
                        key={mapsToField}
                        field={mapsToField}
                        header={title}
                        dataType="numeric"
                        body={template}  //{(rowData) => numericBodyTemplate(rowData, mapsToField)}  //TODO- find any specific reason to use numericBodyTemplate
                        filter={(options) => numericFilterTemplate(options, mapsToField)}
                        {...filterAttributes}
                    />
                );
            }

            return (
                <Column
                    {...sortAttributes}
                    key={mapsToField}
                    field={mapsToField}
                    header={title}
                    body={template}
                    {...filterAttributes}
                />
            )
        }
    }

    return (
        <div id={props.id + '_container'}>
            <FullTextSearch
                id={props.id + '_container_results_FullTextSearch'}
                key={props.currentPage + rows}
                setStatusAction={fetchStatusAction} // Use the provided action for fetching results
                setResultsAction={fetchResultsAction}
                requestSelector={requestSelector}
            />
            <DataTable
                      id={props.id + '_container_dataTable'}
                      value={props.data}
                      size="small"
                      emptyMessage={emptyMessage}
                scrollable
                scrollHeight={scrollHeight ?? 'calc(100vh - 500px)'}
                      showGridlines
                      stripedRows
                      lazy
                      paginator
                      removableSort
                      filters={filters}
                      paginatorPosition={paginatorPosition}
                      paginatorTemplate={paginatorTemplate}
                      selectionMode="single"
                      className="datatable-responsive"
                      dataKey={dataKey}
                      rows={rows}
                      first={first}
                      totalRecords={totalCount}
                      header={header}
                      expandedRows={expandedRows}
                      onRowToggle={(e) => setExpandedRows(e.data)}
                      onRowExpand={onRowExpand}
                      onRowCollapse={onRowCollapse}
                      rowExpansionTemplate={rowExpansionTemplate}
                      rowsPerPageOptions={rowsPerPageOptions}
                      //Using prime icon as flat designs and the user story states to show people icon rather than chevron
                      collapsedRowIcon="pi pi-users" //"fa-light fa-chevron-up"  
                      expandedRowIcon="pi pi-users" //"fa-light fa-chevron-down"
                      onPage={e => lazyLoadData(e)}
                      multiSortMeta={multiSortMeta}
                      sortMode="multiple"
                      onSort={e => lazyLoadData(e)}
                      onFilter={e => lazyLoadData(e)}
                loading={fetchStatus === 'pending'}
            >
                {columnDefinitions.map((col, i) =>
                    createColumn(col.title, col.mapsToField, col.filter, col.expand, col.template, col.sortable, col.filterMatchMode)
                )}
            </DataTable>
        </div>
    )
}

export default CommonGrid
