import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { selectAuthApiHost } from 'globalSelectors'
import NotificationBell from './NotificationBell'
import SearchModeDropDown from './SearchModeDropDown'
import AboutPanel from './AboutPanel'
import BurgerMenu from './BurgerMenu'
import { loadVersionInformation } from 'api/Version/loadVersionInformation'
export function ActionSetRight(props) {
    const logOutClass = 'ml-2 mb-4 mr-2 float-end' //note that we need to replace the 'log out' button with a burger menu component later

    const host = useSelector(selectAuthApiHost)
    const [aboutPanelVisible, setAboutPanelVisibleVisible] = useState(false)
    const [versionInformation, setVersionInformation] = useState({})
    
    const makeAboutPanelVisible = async () => {
        const version = await loadVersionInformation(host)
       setVersionInformation(version)
        // Finally make the panel visible
        setAboutPanelVisibleVisible(true)
    }

    const makeAboutPanelInvisible = () => {
         setAboutPanelVisibleVisible(false)
    }

    
    return (
        <div id='top-cta-right' className='col-auto d-flex justify-content-center top-cta-right'>            
            <SearchModeDropDown id={props.id + '_SearchMode'} className="ml-3 " raised />
            <NotificationBell id={props.id + '_NotificationButton'} />
            <AboutPanel id={props.id + '_About'}
                visible={aboutPanelVisible}
                onHide={makeAboutPanelInvisible}
                currentVersion={versionInformation} />
            <BurgerMenu id={props.id + '_Burger'}
                className={logOutClass}
                showAboutPanel={makeAboutPanelVisible} />
        </div>
    )
}

