import React from 'react'
import StaticCommonGrid from 'components/common/StaticCommonGrid'
import { useSelector, useDispatch } from 'react-redux'
import { FilterMatchMode } from 'primereact/api'
import { Button } from 'primereact/button'
import { selectAudioVisualResultOriginal, selectAudioVisualResultPresentation, selectProgrammeUrl } from 'components/Body/data-entry/AudioVisual/store/selectors'
import { setProgrammeDraftResultsEpisodesAction, clearProgrammeDraftResultsEpisodesAction } from 'components/Body/data-entry/AudioVisual/store/actions'
import { push } from 'redux-first-history'
import { authFetch } from 'api/auth/authFetch'

// ReSharper disable once InconsistentNaming
export default function EpisodesList(props) {
    const programmeData = useSelector(selectAudioVisualResultPresentation)
    const programmeDataOriginal = useSelector(selectAudioVisualResultOriginal)
    const programmeUrl = useSelector(selectProgrammeUrl)
    const episodeDataOriginal = programmeDataOriginal && programmeDataOriginal.episodes ? programmeDataOriginal.episodes : []
    let episodeData = programmeData && programmeData.episodes ? Object.values(programmeData.episodes) : []
    const originalEpisodesCount = programmeData && programmeData.episodesCount ? programmeData.episodesCount : 0
    const dispatch = useDispatch()
    const setAcceptedEpisode = (episode) => dispatch(setProgrammeDraftResultsEpisodesAction(episode))
    const clearUnchangedEpisode = (episode) => dispatch(clearProgrammeDraftResultsEpisodesAction(episode))
    const navigateTo = route => {
        dispatch(push(route)) // Dispatch action to navigate to the specified route
    }
    const displayActions = function (rowData) {
        const handleEpisodeNavigation = () => {
            navigateTo(`/AudioVisual/${programmeDataOriginal.id}/${rowData.id}`)
        }
        return (
            <div id={props.id + '_avList_EpisodeListingGrid_actions'} className="d-flex justify-content-start align-items-center">
                <Button icon="fa-light fa-arrow-up-right" rounded onClick={handleEpisodeNavigation} />
                <Button className="ml-2" icon="pi pi-copy" rounded disabled />
            </div>
        )
    }

    const header = (
        <div className="row d-flex">
            <div className="col mt-1">
                <Button id={props.id + '_container_episodeList_dataTable_add'} className="mr-3 justify-content-start" label="Add Episode" icon="fa-light fa-plus" disabled />
            </div>
            <div className="col-auto mt-1">
                <Button id={props.id + '_container_contribution_dataTable_export_excel'} className="mr-3 justify-content-end" label="Export" icon="fa-light fa-file-import" disabled />
            </div>
        </div>
    );

    const validateData = (rowData) => {
        const errors = {}
        if (!rowData.durationMinutes) errors.durationMinutes = 'Duration is mandatory'
        if (!rowData.yearOfProduction) errors.yearOfProduction = 'Year of Production is mandatory'
        if (!programmeData.isRadio &&
            ((programmeData.programmeTypeId === 8 && programmeData.formatId === 6) ||
                (programmeData.programmeTypeId === 4 && [2, 3, 4, 6].includes(programmeData.formatId)))) {
            if (!rowData.title || rowData.title === '') errors.title = 'Episode Title is mandatory'
            if (!rowData.number) errors.number = 'Episode Number is mandatory'
            if (!rowData.series) errors.series = 'Series Number is mandatory'
        }

        if (rowData.idaCode && String(rowData.idaCode).length !== 12) {
            errors.idaCode = 'IDA Number must be 12 characters long'
        }

        //We need to call checkDuplicateIda(rowData) to validate duplicate Ida number. This is commented out because the asynchronous call is casuing the row editor to close even when validation fails. We need to investigate how to handle this properly

        return errors;
    }

    const checkDuplicateIda = async (rowData) => {
        const endpoint = `${programmeUrl}/${programmeData.id}/Episodes/${rowData.id}/CheckDuplicateIda?idaNumber=${rowData.idaCode}`
        try {
            var response = await authFetch(endpoint)
            var isDuplicate = await response.json()

            if (isDuplicate === true) {
                return 'IDA Number is already in use';
            }
        } catch (error) {
            console.error('Error:', error)
        }
        return undefined
    }

    const handleRowEditSave = (rowData) => {
        const originalData = episodeDataOriginal ? episodeDataOriginal[rowData.id] : null
        if ((originalData && JSON.stringify(originalData) === JSON.stringify(rowData))) {
            clearUnchangedEpisode(rowData.id)
        } else {
            setAcceptedEpisode({ [rowData.id]: rowData })
        }
    }

    const copyDataTemplate = (rowData,field) => {
        return (
            <div id={props.id + '_avList_EpisodeListingGrid_copy'} className="d-flex justify-content-start align-items-center">
                {rowData[field]}
                <Button className="white ml-2" icon="pi pi-copy" rounded onClick={() => navigator.clipboard.writeText( rowData[field])} />
            </div>
        )
    }

    const displayEpisodeCode = function (rowData) {
        return copyDataTemplate(rowData, 'sapCode')
    }

    const displayEpisodeTitle = function (rowData) {
        return copyDataTemplate(rowData, 'title')
    }

    const displayIdaCode = function (rowData) {
        return copyDataTemplate(rowData, 'idaCode')
    }

    const episodeColumnDefinitions = [
        { title: 'Ep.Code', mapsToField: 'sapCode', template: displayEpisodeCode, filter: true, filterMatchMode: FilterMatchMode.STARTS_WITH, sortable: false, editable: false },
        { title: 'Ep.Title', mapsToField: 'title', template: displayEpisodeTitle, filterMatchMode: FilterMatchMode.STARTS_WITH, filter: true, sortable: true, editable: true },       
        { title: 'Ep.No', mapsToField: 'number', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'Series No.', mapsToField: 'series', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'Duration', mapsToField: 'durationMinutes', filter: false, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: true },
        { title: 'Year Produced', mapsToField: 'yearOfProduction', filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: true, editable: true },
        { title: 'IDA No', mapsToField: 'idaCode', template: displayIdaCode, filter: true, filterMatchMode: FilterMatchMode.EQUALS, sortable: false, editable: true },
        { actions: true, template: displayActions }
    ]

    return (
        <div id={props.id + '_episodeList'}>
            {episodeData.length > 0 && (
                <StaticCommonGrid
                    id={props.id + '_episodeList_commonGrid'}
                    dataKey="id"
                    keyColumnName="Ep.Code"
                    data={episodeData}
                    totalCount={originalEpisodesCount}
                    emptyMessage="Programme has no Episodes"
                    columnDefinitions={episodeColumnDefinitions}
                    showPaginator={true}
                    allowRowEdits={true}
                    header={header}
                    selectionMode="multiple"
                    selectionStyle="checkbox"
                    scrollHeight="650px"
                    rowPerPage={10}
                    validateData={validateData}
                    onSave={handleRowEditSave}
                />
            )}
        </div>
    )
}