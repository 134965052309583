import { authFetch } from '../auth/authFetch'
export async function loadVersionInformation(apiHost) {
    try {

        const apiUrl = `${apiHost}/version`
        
        const response = await authFetch(apiUrl, {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json'
            }
        })
        if (!response.ok) {
            throw new Error('Error during network call')
        }
        const parsedResponse = await response.json()
        return parsedResponse
    } catch (error) {
        console.error('There was an error retrieving the data:', error);
    }
    return undefined
}

export default loadVersionInformation
