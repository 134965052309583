import React from 'react';
import { Button } from 'primereact/button';

export const CopyControl = (props) => {

    return (
        <Button
            icon="pi pi-copy"
            className="white"
            rounded
            onClick={() => navigator.clipboard.writeText(props.content)} />
    )
}

