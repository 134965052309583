import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setBreadCrumbStack } from 'components/Header/SecondaryNavigation/store/actions'

export function AudioVisualLanding(props) {  
    const location = useLocation()
    const dispatch = useDispatch()
    const setBreadCrumb = (stack) => dispatch(setBreadCrumbStack(stack))

    const breadcrumbItem = {
        label: 'AV & Radio',
        path: location.pathname
    }

    setBreadCrumb([breadcrumbItem])

    return (
        <div>
            <h1>AV Landing Page</h1>
            <p>Future Implementation</p>
        </div>
    )
}